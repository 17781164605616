<template>
  <div class="q-mx-lg">
    <div class="row q-mt-xl q-mb-xl">
      <div class="col-12 col-md-8">
        <h5 class="q-ma-none text-bold">Download Report</h5>
      </div>
    </div>
    <div>
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="light-blue-6"
        indicator-color="light-blue-6"
        align="left"
        narrow-indicator>
        <q-tab name="elenco" label="Elenco casette" />
        <q-tab name="interventi" label="Interventi" />
        <q-tab name="consumi" label="Consumi" />
        <!-- q-tab name="analisi" label="Qualità dell'acqua" / -->
      </q-tabs>
      <q-separator />
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="elenco">
          <p class="text-body1 text-weight-bold">
            Report in excel delle informazioni di tutte le case dell'acqua
          </p>
          <p>
            Nel dettaglio il report permetterà all'utente di visualizzare per tutte le casette:
          </p>
          <ul>
            <li>stato</li>
            <li>indirizzo</li>
            <li>indicazioni aggiuntive</li>
            <li>comune</li>
            <li>latitudine</li>
            <li>longitudine</li>
            <li>proprietario</li>
            <li>produttore</li>
            <li>manutentore</li>
            <li>data inaugurazione</li>
            <li>data di scadenza contrattuale</li>
            <li>codice casetta e descrizione</li>
          </ul>
          <q-btn
            color="green"
            label="Scarica xls"
            class="q-mt-md"
            @click="elencoCasette">
          </q-btn>
        </q-tab-panel>

        <q-tab-panel name="interventi">
          <p class="text-body1 text-weight-bold">
            Report in excel degli ultimi interventi effettuati su tutte le casette
          </p>
          <p>Nel dettaglio il report permetterà di visualizzare:</p>
          <ul>
            <li>data dell'ultimo rilevamento e i relativi consumi</li>
            <li>data dell'ultima manutenzione effettuata</li>
            <li>
              data di sostituzione del filtro con i relativi litri erogati dalla casetta al momento della sostituzione
            </li>
            <li>
              data di sostituzione delle lampade UV con i relativi giorni rimanenti al momento della sostituzione
            </li>
            <li>data dell'ultima pulizia effettuata</li>
          </ul>
          <q-btn
            color="green"
            label="Scarica xls"
            class="q-mt-md"
            @click="elencoInterventi">
          </q-btn>
        </q-tab-panel>

        <q-tab-panel name="consumi">
          <p class="text-body1 text-weight-bold">
            Report in excel dei consumi per ogni anno relativo a ogni casa dell'acqua
          </p>
          <p>Nel dettaglio il report permetterà di visualizzare:</p>
          <ul>
            <li>elenco dei rilevamenti effettuati</li>
            <li>quantità di acqua registrata per ogni telelettura</li>
          </ul>
          <q-select
            class="q-mt-xl"
            outlined
            style="max-width: 25%; margin-bottom: 15px"
            v-model="casetta"
            :options="casetta_options"
            label="Seleziona Casa dell'Acqua"
            map-options />
          <q-select
            outlined
            style="max-width: 25%; margin-bottom: 15px"
            v-model="anno"
            :options="anno_options"
            label="Seleziona Anno" />
          <q-btn
            :disabled="!anno || !casetta"
            color="green"
            label="Scarica xls"
            class="q-mt-md"
            @click="elencoConsumi">
          </q-btn>
        </q-tab-panel>

        <!-- q-tab-panel name="analisi">
          <p class="text-body1 text-weight-bold">
            Report in excel della qualità dell'acqua per ogni anno relativo a ogni casa dell'acqua
          </p>
          <p>Nel dettaglio il report permetterà di visualizzare:</p>
          <ul>
            <li>elenco dei rilevamenti effettuati</li>
            <li>valore per ogni parametro</li>
          </ul>
          <q-select
            class="q-mt-xl"
            outlined
            style="max-width: 25%; margin-bottom: 15px"
            v-model="casetta"
            :options="casetta_options"
            label="Seleziona Casa dell'Acqua"
            map-options />
          <q-select
            outlined
            style="max-width: 25%; margin-bottom: 15px"
            v-model="anno"
            :options="anno_options"
            label="Seleziona Anno" />
          <q-btn
            :disabled="!anno || !casetta"
            color="green"
            label="Scarica xls"
            class="q-mt-md"
            @click="elencoAnalisi">
          </q-btn>
        </q-tab-panel -->
      </q-tab-panels>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { date } from 'quasar'
import fileSaver from 'file-saver'

export default {
  name: 'DownloadReport',
  data: () => ({
    tab: 'elenco',
    casetta: null,
    casetta_options: [],
    anno: null,
    anno_options: []
  }),
  computed: {},
  mounted() {
    this.getAnno()
    this.getCasette()
  },

  watch: {
    tab: function (newVal) {
      this.casetta = null
      this.anno = null
    }
  },

  methods: {
    getAnno() {
      let thisYear = new Date().getFullYear()
      for (let i = 0; i < 10; i++) {
        this.anno_options.push(String(thisYear - i))
      }
    },

    getCasette() {
      this.$http.get('/api/secure/casette/all', {})
        .then((results) => {
          let data = results.data
          this.$_.forEach(data, (el) => {
            let obj = {}
            obj.value = el.id
            obj.label = el.indirizzo + ' - ' + el.indicazioni_aggiuntive

            this.casetta_options.push(obj)
          })
        })
    },

    elencoCasette() {
      this.$http.get('/api/v1/elenco-casette', {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/octet-stream'
          }
        })
        .then((result) => {
          var byteCharacters = atob(result.data)
          var byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          var byteArray = new Uint8Array(byteNumbers)
          var blob = new Blob([byteArray], {
            type: 'application/vnd.ms-excel'
          })

          fileSaver.saveAs(blob, 'Elenco casette.xls')
        })
    },

    elencoInterventi() {
      this.$http.get('/api/v1/elenco-interventi', {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/octet-stream'
          }
        })
        .then((result) => {
          var byteCharacters = atob(result.data)
          var byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          var byteArray = new Uint8Array(byteNumbers)
          var blob = new Blob([byteArray], {
            type: 'application/vnd.ms-excel'
          })

          fileSaver.saveAs(blob, 'Elenco interventi.xls')
        })
    },

    elencoConsumi() {
      this.$http.get('/api/v1/elenco-consumi', {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          params: {
            casettaId: this.casetta.value,
            anno: this.anno
          }
        })
        .then((result) => {
          var byteCharacters = atob(result.data)
          var byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          var byteArray = new Uint8Array(byteNumbers)
          var blob = new Blob([byteArray], {
            type: 'application/vnd.ms-excel'
          })

          fileSaver.saveAs(blob, 'Consumi.xls')
        })
    }

    /* elencoAnalisi() {
      this.$http.get('/api/v1/elenco-analisi', {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          params: {
            casettaId: this.casetta.value,
            anno: this.anno
          }
        })
        .then((result) => {
          var byteCharacters = atob(result.data)
          var byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          var byteArray = new Uint8Array(byteNumbers)
          var blob = new Blob([byteArray], {
            type: 'application/vnd.ms-excel'
          })

          fileSaver.saveAs(blob, 'Analisi.xls')
        })
    } */
  }
}
</script>

<style>
.q-table--no-wrap th,
.q-table--no-wrap td .text-wrap {
  white-space: normal;
}
@media screen and (max-width: 1750px) {
  .q-table--no-wrap th,
  .q-table--no-wrap td {
    white-space: normal !important;
  }
}
</style>
