<template>
  <q-card>
    <q-card-section>
      <div class="row q-py-md items-center">
        <div class="col-md-6">
          <span class="text-h3">{{ getNumCasetteTotali }}</span> <br />
          case dell'acqua
        </div>
        <div class="col-md-6">
          <div>
            <q-chip
              color="green"
              text-color="white"
              :label="getNumCasetteAttive"
            />
            Attiva
          </div>
          <div>
            <q-chip
              rounded
              color="grey"
              text-color="white"
              :label="getNumCasetteDaAttivare"
            />
            Da attivare
          </div>
          <div>
            <q-chip
              rounded
              color="orange"
              text-color="white"
              :label="getNumCasetteInManutenzione"
            />
            In manutenzione
          </div>
          <div>
            <q-chip
              rounded
              color="red"
              text-color="white"
              :label="getNumCasetteChiuse"
            />
            Chiusa
          </div>
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section
      ><q-btn id="elenco" label="Elenco Case dell'Acqua" outline color="blue" to="/casette"
    /></q-card-section>
  </q-card>
</template>

<script>
export default {
  data: () => ({
    kpiNumCasette: {
      totale: 0,
      attiva: 0,
      da_attivare: 0,
      in_manutenzione: 0,
      chiusa: 0
    }
  }),
  mounted() {
    this.getKpiNumCasette()
  },
  computed: {
    getNumCasetteTotali() {
      return this.kpiNumCasette.totale
    },
    getNumCasetteAttive() {
      return this.kpiNumCasette.attiva
    },
    getNumCasetteChiuse() {
      return this.kpiNumCasette.chiusa
    },
    getNumCasetteDaAttivare() {
      return this.kpiNumCasette.da_attivare
    },
    getNumCasetteInManutenzione() {
      return this.kpiNumCasette.in_manutenzione
    }
  },
  methods: {
    getKpiNumCasette() {
      this.$http
        .get("/api/v1/kpi-num-casette", { })
        .then((result) => {
          if (result.data.length) {
            let data = result.data
            let totale = 0

            this.$_.forEach(data, (el) => {
              if (el.stato === "attiva") {
                this.kpiNumCasette.attiva = Number(el.count)
                totale += Number(el.count)
              } else if (el.stato === "chiusa") {
                this.kpiNumCasette.chiusa = Number(el.count)
                totale += Number(el.count)
              } else if (el.stato === "da attivare") {
                this.kpiNumCasette.da_attivare = Number(el.count)
                totale += Number(el.count)
              } else if (el.stato === "in manutenzione") {
                this.kpiNumCasette.in_manutenzione = Number(el.count)
                totale += Number(el.count)
              }
            })
            this.kpiNumCasette.totale = totale
          } else {
            this.kpiNumCasette = {
              totale: 0,
              attiva: 0,
              da_attivare: 0,
              in_manutenzione: 0,
              chiusa: 0
            }
          }
        })
    }
  }
}
</script>

<style></style>
