<template>
  <div class="q-pt-md">
    <div class="row max-width-100%">
      <q-form @submit="isNew ? save() : update()" class="col">
        <q-select
          outlined
          style="max-width: 25%; margin-bottom: 15px"
          v-model="selectedCasetta.stato"
          :options="statoOptions"
          label="Stato"
          map-options
        />
        <q-input
          outlined
          v-model="selectedCasetta.indirizzo"
          style="max-width: 50%; margin-bottom: 15px"
          label="Indirizzo"
        />
        <q-input
          outlined
          v-model="selectedCasetta.indicazioni_aggiuntive"
          style="max-width: 50%; margin-bottom: 15px"
          label="Indicazioni aggiuntive"
        />
        <q-select
          outlined
          style="max-width: 50%; margin-bottom: 15px"
          v-model="selectedCasetta.comune"
          :options="comuneOptions"
          option-value="descrizione"
          option-label="descrizione"
          label="Comune"
          map-options
        />
        <q-input
          style="
            max-width: 25%;
            width: 25%;
            margin-bottom: 15px;
            margin-right: 10px;
            float: left;
          "
          type="number"
          step="any"
          outlined
          v-model="selectedCasetta.latitudine"
          label="Latitudine"
          hint="es. 46,278527 - Inserire i numeri con la virgola"
        />
        <q-input
          style="max-width: 24%; width: 24%; margin-bottom: 15px"
          type="number"
          step="any"
          outlined
          v-model="selectedCasetta.longitudine"
          label="Longitudine"
          hint=" "
        />
        <q-select
          outlined
          clearable
          style="max-width: 50%; margin-bottom: 15px"
          v-model="selectedCasetta.proprietario"
          :options="proprietarioOptions"
          option-value="descrizione"
          option-label="descrizione"
          label="Proprietario"
          map-options
        />
        <q-select
          outlined
          clearable
          style="max-width: 50%; margin-bottom: 15px"
          v-model="selectedCasetta.produttore"
          :options="produttoreOptions"
          option-value="descrizione"
          option-label="descrizione"
          label="Produttore"
          map-options
        />
        <q-select
          outlined
          clearable
          style="max-width: 50%; margin-bottom: 15px"
          v-model="selectedCasetta.manutentore"
          :options="manutentoreOptions"
          option-value="descrizione"
          option-label="descrizione"
          label="Manutentore"
          map-options
        />
        <q-select
          outlined
          clearable
          style="max-width: 50%; margin-bottom: 15px"
          v-model="selectedCasetta.serie"
          :options="serieOptions"
          option-value="descrizione"
          option-label="descrizione"
          label="Serie delle casette"
          map-options
        />
        <q-input
          outlined
          style="max-width: 25%"
          v-model="selectedCasetta.data_inaugurazione"
          mask="##/##/####"
          :rules="[checkDate]"
          label="Data inaugurazione"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy1"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="selectedCasetta.data_inaugurazione" mask="DD/MM/YYYY">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input
          outlined
          style="max-width: 25%"
          v-model="selectedCasetta.data_scadenza_contratto"
          mask="##/##/####"
          :rules="[checkDate]"
          label="Data scadenza contratto"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy2"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="selectedCasetta.data_scadenza_contratto" mask="DD/MM/YYYY">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input
          style="max-width: 50%; margin-bottom: 25px"
          outlined
          v-model="selectedCasetta.codice_sif"
          label="Codice casetta"
        />
        <label class="text-grey-8">Descrizione</label>
        <q-input
          v-model="selectedCasetta.descrizione"
          type="textarea"
          outlined
        />
        <div class="q-mt-xl q-mb-lg">
          <q-btn
            label="salva"
            type="submit"
            color="light-blue-6"
            v-close-popup
          />
          <q-btn
            v-if="!isNew"
            class="float-right"
            align="around"
            flat
            no-caps
            color="red"
            padding="sm none"
            size="md"
            label="Elimina Casa dell'Acqua"
            @click="showDialog = true"
          />
        </div>
        <div v-if="selectedCasetta.last_user">
          <q-avatar
            flat
            class="q-mr-sm"
            color="grey-7"
            text-color="grey-2"
            icon="person">
          </q-avatar>
          <small v-if="selectedCasetta.last_update">
            Ultima modifica effettuata il {{ selectedCasetta.last_update }}
          </small>
        </div>

        <q-dialog v-model="showDialog">
          <q-card class="q-pa-sm">
            <q-card-section>
              <div class="row">
                <div class="text-h6">Elimina casa dell'acqua</div>
                <q-space />
                <q-btn icon="close" flat round dense v-close-popup />
              </div>
              <div class="text-subtitle2 text-blue-grey-8">
                {{ getSubTitle }}
              </div>
            </q-card-section>

            <q-card-section>
              <div class="row items-center">
                <span v-html="bodyDialog"></span>
              </div>
            </q-card-section>

            <q-card-actions class="q-pr-md q-pb-md" align="right">
              <q-btn flat label="Annulla" v-close-popup />
              <q-btn
                :label="dialoglabel"
                color="red"
                @click="deleteCasetta"
                v-close-popup
              />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </q-form>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { date } from "quasar";

export default {
  name: "Informazioni",
  props: ["source"],
  data: () => ({
    selectedCasetta: {},
    statoOptions: [
      {
        label: "Attiva",
        value: "attiva"
      },
      {
        label: "Chiusa",
        value: "chiusa"
      },
      {
        label: "Da attivare",
        value: "da attivare"
      },
      {
        label: "In manutenzione",
        value: "in manutenzione"
      },
    ],
    comuneOptions: [],
    proprietarioOptions: [],
    produttoreOptions: [],
    manutentoreOptions: [],
    serieOptions: [],
    loading: false,
    showDialog: false,
    bodyDialog:
      "Sei sicuro di voler eliminare questa casetta?<br>Una volta eliminata non sarà più possibile recuperare i dati",
    dialoglabel: "Sì, elimina la casa dell'acqua"
  }),

  computed: {
    isNew() {
      return this.source ? false : true;
    },

    getLastUserImg() {
      let img = null;
      if (this.selectedCasetta.last_user_img)
        img = this.selectedCasetta.last_user_img;
      return img;
    },

    getSubTitle() {
      return this.selectedCasetta
        ? this.selectedCasetta.indirizzo + " " +
          this.selectedCasetta.indicazioni_aggiuntive
        : "";
    }
  },

  mounted() {
    if (!this.isNew)
      this.selectedCasetta = JSON.parse(JSON.stringify(this.source))
    this.getComuni();
    this.getProprietari();
    this.getProduttori();
    this.getManutentori();
    this.getSerieCasette();
  },

  methods: {
    formatDateForDb(val) {
      return date.extractDate(val, "DD/MM/YYYY");
    },

    formatDate(val) {
      return date.formatDate(this.formatDateForDb(val), "DD/MM/YYYY");
    },

    formatDateGet(val) {
      return date.formatDate(date.extractDate(date.formatDate(val)), "DD/MM/YYYY");
    },

    checkDate(val) {
      return (
        date.isValid(date.extractDate(val, "DD/MM/YYYY")) ||
        "Formato non valido."
      );
    },

    fmtData() {
      /* if (this.selectedCasetta.manutentore && typeof this.selectedCasetta.manutentore === 'string') {
        console.log('manutentore stringa', this.manutentoreOptions.filter(el => el.descrizione === this.selectedCasetta.manutentore))
        this.selectedCasetta.manutentore_id = this.manutentoreOptions.filter(el => el.descrizione === this.selectedCasetta.manutentore)[0].id
      }
      else if (this.selectedCasetta.manutentore && typeof this.selectedCasetta.manutentore === 'object') {
        console.log('manutentore oggetto', this.selectedCasetta.manutentore.id)
        this.selectedCasetta.manutentore_id = this.selectedCasetta.manutentore.id
      } */

      this.selectedCasetta.stato = this.selectedCasetta.stato.value
      this.selectedCasetta.comune = this.selectedCasetta.comune ? this.selectedCasetta.comune.descrizione : "",
      this.selectedCasetta.proprietario = this.selectedCasetta.proprietario ? this.selectedCasetta.proprietario.descrizione : null,
      this.selectedCasetta.produttore = this.selectedCasetta.produttore ? this.selectedCasetta.produttore.descrizione : null,
      this.selectedCasetta.manutentore = this.selectedCasetta.manutentore ? this.selectedCasetta.manutentore.descrizione : null,
      this.selectedCasetta.serie = this.selectedCasetta.serie ? this.selectedCasetta.serie.descrizione : "",
      this.selectedCasetta.data_inaugurazione = this.selectedCasetta.data_inaugurazione
        ? this.formatDateForDb(this.selectedCasetta.data_inaugurazione)
        : null,
      this.selectedCasetta.data_scadenza_contratto = this.selectedCasetta.data_scadenza_contratto
        ? this.formatDateForDb(this.selectedCasetta.data_scadenza_contratto)
        : null,
      delete this.selectedCasetta.allarmi
      delete this.selectedCasetta.folders
      delete this.selectedCasetta.interventi
    },

    save() {
      this.fmtData()
      this.$http
        .post("/api/secure/casetta/save", this.selectedCasetta, { })
        .then((results) => {
          this.$q.notify({
            message: "Salvataggio riuscito",
            color: "green",
            timeout: 1000,
          });
          this.$router.push({
            name: "Casetta",
            params: { id: results.data.id }
          });
        })
        .catch((err) => {
          this.$q.notify({
            message: "Errore durante il salvataggio",
            color: "red",
            timeout: 1000,
          });
        });
    },
    update() {
      this.fmtData()
      this.$http
        .put("/api/secure/casetta/update", this.selectedCasetta, { })
        .then((results) => {
          this.$q.notify({
            message: "Aggiornamento riuscito",
            color: "green",
            timeout: 1000
          });
          console.info(
            "Aggiornamento riuscito casetta id:" + this.$route.params.id
          );
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$q.notify({
            message: "Errore durante l'aggiornamento",
            color: "red",
            timeout: 1000
          });
        });
    },

    deleteCasetta() {
      let id = this.$route.params.id;
      this.$http
        .delete("/api/secure/casetta/delete", {
          params: { id: id }
        })
        .then((results) => {
          this.$q.notify({
            message: "Cancellazione riuscita",
            color: "green",
            timeout: 1000
          });
          console.info("Cancellazione riuscita");
          this.$router.push("/casette");
        })
        .catch((err) => {
          this.$q.notify({
            message: "Errore durante la cancellazione",
            color: "red",
            timeout: 1000
          });
          console.info("Errore durante la cancellazione", err);
        });
    },

    getProprietari() {
      this.$http
        .get("/api/secure/anagrafiche/get-by-type", {
          params: { tipo: "proprietario" }
        })
        .then((results) => {
          if (results.data.length) this.proprietarioOptions = results.data;
          else this.proprietarioOptions = [];
        })
        .catch((err) => {
          console.log("Error on getProprietari", err);
        });
    },

    getProduttori() {
      this.$http
        .get("/api/secure/anagrafiche/get-by-type", {
          params: { tipo: "produttore" }
        })
        .then((results) => {
          if (results.data.length) this.produttoreOptions = results.data;
          else this.produttoreOptions = [];
        })
        .catch((err) => {
          console.log("Error on getProduttori", err);
        });
    },

    getManutentori() {
      this.$http
        .get("/api/secure/anagrafiche/get-by-type", {
          params: { tipo: "manutentore" }
        })
        .then((results) => {
          if (results.data.length) this.manutentoreOptions = results.data;
          else this.manutentoreOptions = [];
        })
        .catch((err) => {
          console.log("Error on getManutentori", err);
        });
    },

    getComuni() {
      this.$http
        .get("/api/secure/anagrafiche/get-by-type", {
          params: { tipo: "comune" }
        })
        .then((results) => {
          if (results.data.length) this.comuneOptions = results.data;
          else this.comuneOptions = [];
        })
        .catch((err) => {
          console.log("Error on getComuni", err);
        });
    },

    getSerieCasette() {
      this.$http
        .get("/api/secure/anagrafiche/get-by-type", {
          params: { tipo: "serie" }
        })
        .then((results) => {
          if (results.data.length) this.serieOptions = results.data;
          else this.serieOptions = [];
        })
        .catch((err) => {
          console.log("Error on getSerieCasette", err);
        });
    }
  }
};
</script>

<style></style>
