<template>
  <div class="full-width column wrap">
    <q-card
      v-for="folder in folders"
      :key="folder.name"
      flat
      bordered
      class="my-card bg-grey-1 q-mt-md q-mb-lg">
      <q-card-section>
        <div class="row">
          <div class="col q-mt-xs">
            <div class="text-body1 text-weight-bold">{{ folder.name }}</div>
          </div>
          <q-btn
            size="sm"
            flat
            round
            icon="edit"
            @click="editCategoria(folder)">
          </q-btn>
          <q-btn
            flat
            round
            size="sm"
            icon="delete"
            @click="deleteCategoria(folder)">
          </q-btn>
        </div>
      </q-card-section>
      <q-separator />

      <q-card-section>
        <div class="fit row wrap justify-start">
          <q-uploader
            color="primary"
            class="col-sm-6"
            ref="myUploader"
            :url="uploadUrl"
            label="Upload files"
            multiple
            square
            batch
            style="height: 200px"
            @uploaded="callDb"
            @start="takeCategoria(folder)">
            <template v-slot:header="scope">
              <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
                <q-btn
                  v-if="scope.queuedFiles.length"
                  icon="clear_all"
                  @click="scope.removeQueuedFiles"
                  round
                  dense
                  flat>
                  <q-tooltip>Cancella tutti</q-tooltip>
                </q-btn>
                <q-btn
                  v-if="scope.uploadedFiles.length"
                  icon="done_all"
                  @click="scope.removeUploadedFiles"
                  round
                  dense
                  flat>
                  <q-tooltip>Rimuovi file caricati</q-tooltip>
                </q-btn>
                <q-spinner
                  v-if="scope.isUploading"
                  class="q-uploader__spinner" />
                <div class="col">
                  <div class="q-uploader__title">
                    Carica file trascinandoli in questo riquadro
                  </div>
                  <div class="q-uploader__subtitle">
                    {{ scope.uploadSizeLabel }} /
                    {{ scope.uploadProgressLabel }}
                  </div>
                </div>
                <q-btn
                  v-if="scope.canAddFiles"
                  type="a"
                  icon="add_box"
                  round
                  dense
                  flat>
                  <q-uploader-add-trigger />
                  <q-tooltip>Scegli file</q-tooltip>
                </q-btn>
                <q-btn
                  v-if="scope.canUpload"
                  icon="cloud_upload"
                  @click="scope.upload"
                  dense
                  flat>
                  <span class="q-ml-xs">Carica File</span>
                  <q-tooltip>Carica File</q-tooltip>
                </q-btn>
                <q-btn
                  v-if="scope.isUploading"
                  icon="clear"
                  @click="scope.abort"
                  round
                  dense
                  flat>
                  <q-tooltip>Annulla upload</q-tooltip>
                </q-btn>
              </div>
            </template>
          </q-uploader>
        </div>
        <small>
          Ricordati di cliccare sul bottone <em>Carica File</em> per effettuare l'upload
        </small>

        <div class="fit row wrap justify-start q-mt-lg">
          <div class="col-md-12">
            <div style="margin-top: 10px">
              <q-table
                table-header-class="text-grey-7"
                :title="labelTable"
                :data="folder.files"
                :columns="columns"
                row-key="name"
                :grid="$q.screen.xs"
                :filter="filter"
                :pagination="pagination">
                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td key="url" :props="props">
                      <span class="text-wrap">{{ props.row.url }}</span>
                    </q-td>
                    <q-td key="name" :props="props">
                      <q-icon name="create" class="text-blue-grey-4" />
                      {{ props.row.name }}
                      <q-popup-edit
                        ref="inline"
                        v-model="props.row.name"
                        @save="(val, init) => saveInline(props.row)">
                        <q-input
                          v-model="props.row.name"
                          dense
                          autofocus
                          counter />
                      </q-popup-edit>
                    </q-td>
                    <q-td key="data" :props="props">
                      {{ formatDate(props.row.updatedAt) }}
                    </q-td>
                    <q-td key="actions" :props="props">
                      <q-btn-dropdown
                        no-caps
                        size="12px"
                        color="blue-grey-2 text-black"
                        label="Azioni">
                        <q-list>
                          <q-item
                            clickable
                            v-close-popup
                            @click="downloadFile(props)">
                            <q-item-section>
                              <q-item-label>
                                <q-icon class="q-mr-sm" name="save_alt" />
                                Scarica file
                              </q-item-label>
                            </q-item-section>
                          </q-item>

                          <q-item
                            clickable
                            v-close-popup
                            @click="deleteDocumento(props)">
                            <q-item-section>
                              <q-item-label>
                                <q-icon class="q-mr-sm" name="delete" />
                                Cancella
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-btn-dropdown>
                    </q-td>
                  </q-tr>
                </template>

                <template v-slot:top-right>
                  <q-input
                    filled
                    dense
                    debounce="300"
                    v-model="filter"
                    placeholder="Cerca">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
              </q-table>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>

    <div class="q-mt-lg">
      <q-btn color="light-blue-6" @click="addCategoria()" icon="add">
        Aggiungi categoria
      </q-btn>
    </div>

    <q-dialog v-model="showDialog" persistent square>
      <q-card class="q-pa-sm" style="width: 400px">
        <q-card-section>
          <div class="row">
            <div class="text-h6">{{ titleDialog }}</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </div>
          <div class="text-subtitle2 text-blue-grey-8">
            {{ subTitleDialog }}
          </div>
        </q-card-section>

        <q-card-section v-if="action === 'add' || action === 'edit'">
          <div class="row items-center">
            <q-input
              style="width: 100%; margin-bottom: 15px"
              outlined
              v-model="folderName"
              label="Nome"
              dense />
          </div>
        </q-card-section>

        <q-card-section v-if="action === 'delete'">
          <div class="row items-center">
            <span v-html="bodyDialog"></span>
          </div>
        </q-card-section>

        <q-card-actions class="q-pr-md q-pb-md" align="right">
          <q-btn flat label="Annulla" v-close-popup />
          <q-btn
            :label="dialoglabel"
            :color="dialogColor"
            @click="dialogAction"
            v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { date } from 'quasar'

export default {
  name: 'Documenti',
  props: ['source'],
  data: () => ({
    uploadUrl: '/api/cos/upload',
    labelTable: 'Documenti',
    pagination: {
      sortBy: 'data',
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: 'url',
        required: true,
        label: 'FILE',
        align: 'left',
        field: (row) => row.url,
        sortable: true
      },
      {
        name: 'name',
        required: true,
        label: 'NOME DOCUMENTO',
        align: 'left',
        field: (row) => row.name,
        sortable: true
      },
      {
        name: 'data',
        align: 'left',
        label: 'DATA',
        field: 'updatedAt',
        format: (val) => date.formatDate(val, 'DD/MM/YYYY HH:mm:ss'),
        sortable: true
      },
      {
        name: 'actions',
        align: 'right',
        label: 'AZIONI'
      }
    ],
    filter: '',
    showDialog: false,
    titleDialog: '',
    subTitleDialog: '',
    bodyDialog: '',
    dialoglabel: '',
    dialogColor: '',
    action: '',
    currentElem: null,
    folders: [],
    folderName: null
  }),
  computed: {},
  mounted() {
    this.getData()
  },

  methods: {
    formatDate(val) {
      return date.formatDate(val, 'DD/MM/YYYY - HH:mm:ss')
    },

    getData() {
      this.$http.get('/api/secure/folders/casetta', {
          params: { casettaId: this.$route.params.id }
        })
        .then((result) => {
          if (result.data.length) {
            this.folders = result.data
          } else {
            this.folders = []
          }
        })
    },

    addCategoria() {
      this.action = 'add'
      this.titleDialog = 'Aggiungi categoria'
      this.subTitleDialog = ''
      this.dialoglabel = 'Salva'
      this.dialogColor = 'light-blue-6'
      this.showDialog = true
    },

    editCategoria(folder) {
      this.currentElem = folder
      this.folderName = folder.name
      this.action = 'edit'
      this.titleDialog = 'Modifica categoria'
      this.subTitleDialog = ''
      this.dialoglabel = 'Salva'
      this.dialogColor = 'light-blue-6'
      this.showDialog = true
    },

    deleteCategoria(folder) {
      this.currentElem = folder
      this.action = 'delete'
      this.titleDialog = 'Elimina categoria'
      this.subTitleDialog = folder.name
      this.bodyDialog =
        'Sei sicuro di voler eliminare questa categoria?<br>Oltre alla categoria, verranno <b>cancellati anche tutti i file</b>'
      this.dialoglabel = 'Sì, elimina categoria'
      this.dialogColor = 'red'
      this.showDialog = true
    },

    deleteDocumento(element) {
      this.currentElem = element
      this.action = 'delete_file'
      this.titleDialog = 'Elimina documento'
      this.subTitleDialog = element.row.name
      this.bodyDialog =
        'Sei sicuro di voler eliminare questo documento?<br>' +
        'Il documento non sarà più recuperabile'
      this.dialoglabel = 'Sì, elimina documento'
      this.dialogColor = 'red'
      this.showDialog = true
    },

    dialogAction() {
      if (this.action === 'add') {
        if (this.folderName) this.salvaFolder(this.folderName)
      } else if (this.action === 'edit') this.modificaFolder(this.currentElem)
      else if (this.action === 'delete') this.cancellaFolder(this.currentElem)
      else if (this.action === 'delete_file')
        this.cancellaFile(this.currentElem)
    },

    salvaFolder(nome) {
      let toSave = {
        casetta_id: this.$route.params.id,
        name: nome
      }
      this.$http
        .post('/api/secure/folder/save', toSave, {})
        .then((results) => {
          this.$q.notify({
            message: 'Categoria aggiunta correttamente',
            color: 'green',
            timeout: 2000
          })
          this.folders.push(results.data)
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Problemi aggiunta categoria',
            color: 'red',
            timeout: 2000
          })
          console.info('Problemi aggiunta categoria', err)
        })
    },

    modificaFolder() {
      let toUpdate = this.currentElem
      toUpdate.name = this.folderName

      this.$http
        .put('/api/secure/folder/update', toUpdate, {})
        .then(() => {
          this.$q.notify({
            message: 'Categoria modificata correttamente',
            color: 'green',
            timeout: 2000
          })
          this.getData()
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Problemi modifica categoria',
            color: 'red',
            timeout: 2000
          })
          console.info('Problemi modifica categoria', err)
        })
    },

    cancellaFolder() {
      let id = this.currentElem.id

      this.$http
        .delete('/api/secure/folder/delete', { params: { id: id } })
        .then(() => {
          this.$q.notify({
            message: 'Categoria eliminata correttamente',
            color: 'green',
            timeout: 2000
          })
          this.getData()
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Problemi cancellazione categoria',
            color: 'red',
            timeout: 2000
          })
          console.info('Problemi cancellazione categoria', err)
        })
    },

    takeCategoria(folder) {
      this.currentElem = folder
    },

    //chiamata post-upload files x aggiornamento db
    callDb(info) {
      let files = this.$_.map(info.files, 'name')
      this.salvaFiles(files, this.currentElem)

      //reset after upload
      this.$_.forEach(this.$refs.myUploader, (el) => {
        el.reset()
      })
    },

    salvaFiles(files, folder) {
      let arrayToSave = []

      this.$_.forEach(files, (file) => {
        let toSave = {
          name: file,
          url: file,
          folder_id: folder.id
        }

        let saveRequest = this.$http2.post('/api/secure/file/save', toSave, {})
        arrayToSave.push(saveRequest)
      })
      this.$http2
        .all(arrayToSave)
        .then(() => {
          this.$q.notify({
            message: 'Caricamento riuscito',
            color: 'green',
            timeout: 1000
          })
          this.getData()
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Errore durante il caricamento',
            color: 'red',
            timeout: 1000
          })
          console.info('Errore durante il caricamento', err)
        })
    },

    //update dell'edit inline
    saveInline(row) {
      let toUpdate = {
        id: row.id,
        folder_id: row.folder_id,
        name: row.name,
        url: row.url
      }

      this.$http
        .put('/api/secure/file/update', toUpdate)
        .then(() => {
          this.$q.notify({
            message: 'Aggiornamento file riuscito',
            color: 'green',
            timeout: 2000
          })
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Errore aggiornamento file',
            color: 'red',
            timeout: 2000
          })
          console.log('Errore aggiornamento file', err)
        })
    },

    //method cos (cloud object storage)
    downloadFile(elem) {
      this.$http
        .get('/api/cos/get/', {
          params: { filename: elem.row.url }
        })
        .then((response) => {
          var a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          var blob = new Blob([Buffer.from(response.data.Body)], {
            type: response.data.ContentType
          })

          var url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = response.data.Metadata.filename //response.config.params.filename
          a.click()
          window.URL.revokeObjectURL(url)
        })
    },

    //logica di cancellazione lato cos (cloud object storage)
    deleteFileCos(filename) {
      return this.$http.delete('/api/cos/delete/', {
        params: { filename: filename }
      })
    },

    //logica di cancellazione lato db
    deleteFileDb(element) {
      let id = element.row.id

      this.$http
        .delete('/api/secure/file/delete', {
          params: { id: id }
        })
        .then(() => {
          this.$q.notify({
            message: 'Cancellazione file eseguita con successo',
            color: 'green',
            timeout: 2000
          })
          this.getData()
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Delete error on DB',
            color: 'red',
            timeout: 2000
          })
          console.log('Problemi durante la cancellazione file (DB)', err)
        })
    },

    //cancellazione dati
    cancellaFile(element) {
      this.deleteFileCos(element.row.url)
        .then(() => {
          this.deleteFileDb(element)
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Delete error on COS',
            color: 'red',
            timeout: 2000
          })
          console.log('Problemi durante la cancellazione file (COS)', err)
        })
    }
  }
}
</script>

<style>
.q-table--no-wrap th,
.q-table--no-wrap td .text-wrap {
  white-space: normal;
}
@media screen and (max-width: 1750px) {
  .q-table--no-wrap th,
  .q-table--no-wrap td {
    white-space: normal !important;
  }
}
.q-table__title {
  font-size: 14px !important;
  font-weight: bold !important;
}
</style>
