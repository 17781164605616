<template>
  <div class="q-mx-lg">
    <div class="row q-mt-xl q-mb-xl">
      <div class="col-12 col-md-8">
        <h5 class="q-ma-none text-bold">Operatori per notifiche Alert</h5>
      </div>
      <div class="col-12 col-md-4 text-right">
        <q-btn
          no-caps
          stretch
          color="light-blue-6"
          @click="modificaOperatore()"
          icon="add"
          >Nuovo Operatore</q-btn
        >
      </div>
    </div>
    <div>
      <div class="row max-width-100%">
        <q-input
          class="col col-md-8"
          style="max-width: 30%; margin-right: 10px"
          outlined
          color="black"
          dense
          v-model="filter"
          debounce="300"
          placeholder="Cerca operatore"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>
    <div class="q-mt-lg">
      <q-table
        table-header-class="bg-blue-grey-1 text-grey-8"
        class="casette-table"
        :data="dati"
        :columns="columns"
        :grid="$q.screen.xs"
        :filter="filter"
        :pagination="pagination"
        row-key="nome"
      >
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn-dropdown
              size="11px"
              color="blue-grey-2 text-black"
              label="Azioni"
            >
              <q-list>
                <q-item
                  v-if="!props.row.edit"
                  clickable
                  v-close-popup
                  @click="modificaOperatore(props.row)"
                >
                  <q-item-section>
                    <q-item-label
                      ><q-icon class="q-mr-sm" name="edit" /> Modifica
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-close-popup
                  @click="deleteOperatore(props.row)"
                >
                  <q-item-section>
                    <q-item-label
                      ><q-icon class="q-mr-sm" name="delete" />
                      Elimina</q-item-label
                    >
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </q-td>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="showDialog" persistent square>
      <q-card class="q-pa-sm" style="width: 350px">
        <q-card-section>
          <div class="row">
            <div class="text-h6">Nuovo operatore</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup @click="reset" />
          </div>
        </q-card-section>

        <q-card-section>
          <div>
            <q-input
              outlined
              v-model="operatore.nome"
              label="Nome Completo*"
              dense
              :rules="[(val) => !!val || '* Obbligatorio']"
              lazy-rules
            />
          </div>
          <div>
            <q-input
              outlined
              v-model="operatore.email"
              label="Email*"
              dense
              :rules="[(val) => !!val || '* Obbligatorio']"
              lazy-rules
            />
          </div>
          <div>
            <q-input
              outlined
              v-model="operatore.telefono"
              label="Telefono"
              dense
            />
          </div>
        </q-card-section>

        <q-card-actions class="q-pr-md q-pb-md" align="right">
          <q-btn
            style="text-decoration: underline"
            no-caps
            flat
            color="black"
            label="annulla"
            v-close-popup
            @click="reset"
          />
          <q-btn
            :disabled="!operatore.nome || !operatore.email"
            label="Salva"
            color="light-blue-6"
            @click="addOperatore()"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showDeleteDialog" persistent square>
      <q-card class="q-pa-sm" style="width: 350px">
        <q-card-section>
          <div class="row">
            <div class="text-h6">Elimina operatore</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup @click="reset" />
          </div>
          <div class="text-subtitle2 text-blue-grey-8">
            {{ operatore.nome }}
          </div>
        </q-card-section>

        <q-card-section>
          <div>
            <p>Sei sicuro di voler eliminare questo operatore?</p>
          </div>
        </q-card-section>

        <q-card-actions class="q-pr-md q-pb-md" align="right">
          <q-btn
            style="text-decoration: underline"
            no-caps
            flat
            color="black"
            label="annulla"
            v-close-popup
          />
          <q-btn
            label="Si, elimina operatore"
            color="red"
            no-caps
            @click="deleteOperatoreDb()"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'operatoriAllerte',
  data: () => ({
    dati: [],
    operatore: {
      telefono: null,
      nome: null,
      email: null
    },
    showDeleteDialog: false,
    pagination: {
      sortBy: 'ultima_modifica',
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: 'operatore',
        label: 'NOME COMPLETO',
        field: 'nome',
        sortable: true,
        align: 'left',
        style: 'width: 20%'
      },
      {
        name: 'email',
        label: 'EMAIL',
        field: 'email',
        align: 'left',
        style: 'width: 40%'
      },
      {
        name: 'telefono',
        label: 'TELEFONO',
        field: 'telefono',
        align: 'left',
        style: 'width: 20%'
      },
      {
        name: 'actions',
        label: 'AZIONI',
        align: 'right',
        style: 'width: 20%'
      }
    ],
    filter: '',
    showDialog: false
  }),

  mounted() {
    this.getData()
  },

  watch: {},

  methods: {
    reset() {
      this.operatore.telefono = null
      this.operatore.nomeCompleto = null
      this.operatore.email = null
      this.showDialog = false
    },
    modificaOperatore(item) {
      this.showDialog = true
      this.operatore = Object.assign({}, item)
    },
    addOperatore() {
      if (this.operatore.id) {
        this.$http
          .put('/api/secure/operatori/update', this.operatore)
          .then(() => {
            this.$q.notify({
              message: 'Salvataggio riuscito',
              color: 'green',
              timeout: 1000
            })
            this.getData()
            this.operatore.email = null
            this.operatore.nome = null
            this.operatore.telefono = null
          })
          .catch((err) => {
            console.log('Error on saveData Anagrafiche', err)
            this.$q.notify({
              message: 'Errore durante il salvataggio',
              color: 'red',
              timeout: 1000
            })
          })
      } else {
        this.$http
          .post('/api/secure/operatori/save', this.operatore)
          .then(() => {
            this.$q.notify({
              message: 'Salvataggio riuscito',
              color: 'green',
              timeout: 1000
            })
            this.getData()
            this.operatore.email = null
            this.operatore.nome = null
            this.operatore.telefono = null
          })
          .catch((err) => {
            console.log('Error on saveData Anagrafiche', err)
            this.$q.notify({
              message: 'Errore durante il salvataggio',
              color: 'red',
              timeout: 1000
            })
          })
      }
    },
    deleteOperatore(row) {
      this.showDeleteDialog = true
      this.operatore = row
    },
    deleteOperatoreDb() {
      this.$http
        .delete('/api/secure/operatori/delete', {
          params: {
            id: this.operatore.id
          }
        })
        .then(() => {
          this.$q.notify({
            message: 'Salvataggio riuscito',
            color: 'green',
            timeout: 1000
          })
          this.getData()
          this.email = null
          this.nomeCompleto = null
          this.telefono = null
        })
        .catch((err) => {
          console.log('Error on saveData Anagrafiche', err)
          this.$q.notify({
            message: 'Errore durante il salvataggio',
            color: 'red',
            timeout: 1000
          })
        })
    },
    getData() {
      this.$http
        .get('/api/secure/operatori/getAll', {
          /*  headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          }, */
        })
        .then((results) => {
          this.dati = results.data
        })
    }
  }
}
</script>
<style>
.casette-table .q-table tr td:first-child {
  width: 25%;
}
.casette-table.q-table--no-wrap td,
.casette-table.q-table--no-wrap th {
  white-space: normal !important;
}
</style>
