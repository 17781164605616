<template>
  <div class="q-mx-lg">
    <div class="row q-mt-xl q-mb-xl">
      <div class="col-12 col-md-8">
        <h5 class="q-ma-none text-bold">Utenti e Ruoli</h5>
      </div>
    </div>
    <div>
      <div class="row max-width-100%">
        <q-input
          class="col col-md-8"
          style="max-width: 30%; margin-right: 10px"
          outlined
          color="black"
          dense
          v-model="filter"
          debounce="300"
          placeholder="Cerca utente o ruolo">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>
    <div class="q-mt-lg">
      <q-table
        table-header-class="bg-blue-grey-1 text-grey-8"
        class="casette-table"
        :data="dati"
        :columns="columns"
        :grid="$q.screen.xs"
        :filter="filter"
        :pagination="pagination">
        <template v-slot:body-cell-ruoli="props">
          <q-td :props="props">
            <div v-if="props.row.ruoli.length">
              <div v-for="(ruolo, index) in props.row.ruoli" :key="index">
                <q-badge v-if="ruolo === 'admin'" color="red" label="admin" />
                <q-badge
                  v-if="ruolo === 'casette'"
                  color="primary"
                  label="casette"
                  class="float-left q-mr-xs" />
                <q-badge
                  v-if="ruolo === 'impostazioni'"
                  color="pink"
                  label="impostazioni"
                  class="float-left q-mr-xs" />

                <q-badge
                  v-if="ruolo === 'statistiche'"
                  color="green"
                  label="statistiche"
                  class="float-left q-mr-xs" />

                <q-badge
                  v-if="ruolo === 'operatore'"
                  color="orange"
                  label="operatore"
                  class="float-left q-mr-xs" />
              </div>
            </div>
            <div v-else>
              <q-badge
                color="grey"
                class="float-left q-mr-xs"
                label="da attivare" />
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-ultima_modifica="props">
          <q-td :props="props">
            {{ formatDate(props.row.createdAt) }}
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              size="11px"
              no-caps
              color="blue-grey-2 text-black"
              label="Dettagli"
              @click="openDetails(props)" />
          </q-td>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="showDialog" persistent square>
      <q-card class="q-pa-sm" style="width: 350px">
        <q-card-section>
          <div class="row">
            <div class="text-h6">Aggiorna ruoli</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup @click="reset" />
          </div>
          <div class="text-subtitle2 text-blue-grey-8">
            {{ subTitleDialog }}
          </div>
        </q-card-section>

        <q-card-section>
          <div>
            <q-toggle label="admin" color="red" v-model="togl_admin" />
          </div>
          <div>
            <q-toggle
              label="casette"
              color="primary"
              v-model="togl_casette"
              :disable="toggle_disable" />
          </div>
          <div>
            <q-toggle
              label="impostazioni"
              color="pink"
              v-model="togl_impostazioni"
              :disable="toggle_disable" />
          </div>
          <div>
            <q-toggle
              label="statistiche"
              color="green"
              v-model="togl_statistiche"
              :disable="toggle_disable" />
          </div>
          <div>
            <q-toggle
              label="operatore"
              color="orange"
              v-model="togl_operatore"
              :disable="toggle_disable" />
          </div>
          <div>
            <q-select
              v-if="togl_operatore"
              outlined
              style="max-width: 100%; margin-bottom: 15px"
              v-model="manutentore"
              :options="manutentore_options"
              label="Manutentore"
              option-value="descrizione"
              option-label="descrizione"
              map-options />
          </div>
        </q-card-section>

        <q-card-actions class="q-pr-md q-pb-md" align="right">
          <q-btn
            style="text-decoration: underline"
            no-caps
            flat
            color="black"
            label="annulla"
            v-close-popup
            @click="reset" />
          <q-btn
            label="Salva"
            color="light-blue-6"
            @click="updateRoles"
            v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { date } from 'quasar'

export default {
  name: 'Ruoli',
  data: () => ({
    dati: [],
    pagination: {
      sortBy: 'ultima_modifica',
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: 'utente',
        align: 'left',
        label: 'UTENTE',
        field: 'email',
        sortable: true,
        style: 'width: 20%'
      },
      {
        name: 'ruoli',
        align: 'left',
        label: 'RUOLI',
        field: 'ruoli',
        sortable: true,
        style: 'width: 45%'
      },
      {
        name: 'ultima_modifica',
        align: 'right',
        label: 'DATA',
        field: 'updatedAt',
        format: (val) => date.formatDate(val, 'DD/MM/YYYY HH:mm'),
        sortable: true,
        style: 'width: 20%'
      },
      {
        name: 'actions',
        align: 'right',
        label: 'AZIONI',
        style: 'width: 15%'
      }
    ],
    filter: '',
    showDialog: false,
    subTitleDialog: '',
    togl_admin: false,
    togl_casette: false,
    togl_impostazioni: false,
    togl_statistiche: false,
    togl_operatore: false,
    toggle_disable: false,
    currentElement: null,
    manutentore: null,
    manutentore_options: []
  }),

  mounted() {
    this.getData()
    this.getManutentore()
  },

  watch: {
    togl_admin(newVal) {
      if (newVal) {
        this.togl_casette = false
        this.togl_impostazioni = false
        this.togl_statistiche = false
        this.togl_operatore = false
        this.toggle_disable = true
      } else {
        this.toggle_disable = false
      }
    }
  },

  methods: {
    formatDate(val) {
      return date.formatDate(val, 'DD/MM/YYYY - HH:mm')
    },

    openDetails(data) {
      this.currentElement = data.row
      if (this.$_.includes(this.currentElement.ruoli, 'admin'))
        this.togl_admin = true
      if (this.$_.includes(this.currentElement.ruoli, 'casette'))
        this.togl_casette = true
      if (this.$_.includes(this.currentElement.ruoli, 'impostazioni'))
        this.togl_impostazioni = true
      if (this.$_.includes(this.currentElement.ruoli, 'statistiche'))
        this.togl_statistiche = true
      if (this.$_.includes(this.currentElement.ruoli, 'operatore'))
        this.togl_operatore = true

      if (this.currentElement.manutentore)
        this.manutentore = this.currentElement.manutentore

      this.subTitleDialog = this.currentElement.email
      this.showDialog = true
    },

    reset() {
      this.currentElement = null
      this.togl_admin = false
      this.togl_casette = false
      this.togl_impostazioni = false
      this.togl_statistiche = false
      this.togl_operatore = false

      this.subTitleDialog = ''
      this.showDialog = false
    },

    updateRoles() {
      let roles = []
      if (this.togl_admin) roles.push('admin')
      if (this.togl_casette) roles.push('casette')
      if (this.togl_impostazioni) roles.push('impostazioni')
      if (this.togl_statistiche) roles.push('statistiche')
      if (this.togl_operatore) roles.push('operatore')

      let toUpdate = {
        id: this.currentElement.id,
        email: this.currentElement.email,
        ruoli: roles,
        manutentore: null
      }
      if (this.manutentore) toUpdate.manutentore = this.manutentore.descrizione

      this.$http.put('/api/secure/user/update', toUpdate, {})
        .then(() => {
          this.getData()
        })
    },

    getData() {
      this.$http.get('/api/secure/users/all', {})
        .then((results) => {
          this.dati = results.data
        })
    },

    getManutentore() {
      this.$http.get('/api/secure/anagrafiche/get-by-type', {
        params: { tipo: 'manutentore' }
      })
      .then((results) => {
        this.manutentore_options = results.data
      })
      .catch((err) => {
        console.log('Error on getData Anagrafiche', err)
      })
    }
  }
}
</script>

<style>
.casette-table .q-table tr td:first-child {
  width: 25%;
}
.casette-table.q-table--no-wrap td,
.casette-table.q-table--no-wrap th {
  white-space: normal !important;
}
</style>
