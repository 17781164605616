<template>
  <div>
    <div class="q-mt-md">
      <q-table
        table-header-class="text-grey-7"
        class="sticky-header-table"
        style="height: 65vh"
        virtual-scroll
        dense
        title="Consumi"
        :data="consumi"
        :columns="columns"
        row-key="time_stamp"
        :grid="$q.screen.xs"
        :loading="loading"
        :filter="filter"
        :pagination.sync="pagination"
        :rows-per-page-options="[]"
        @request="onRequest"
        binary-state-sort>
        <template v-slot:top-right>
          <q-input
            outlined
            v-model="filter.dal"
            mask="##/##/####"
            :rules="[checkDate]"
            label="Dal"
            dense
            class="q-ml-md q-mt-md"
            style="width: 150px">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy1i"
                  transition-show="scale"
                  transition-hide="scale">
                  <q-date v-model="filter.dal" mask="DD/MM/YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-input
            outlined
            v-model="filter.al"
            mask="##/##/####"
            :rules="[checkDate]"
            label="Al"
            dense
            class="q-ml-md q-mt-md"
            style="width: 150px">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy2i"
                  transition-show="scale"
                  transition-hide="scale">
                  <q-date v-model="filter.al" mask="DD/MM/YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="time_stamp" :props="props">
              {{ formatDate(props.row.time_stamp) }}
            </q-td>
            <q-td key="frizzante" :props="props">
              <span class="text-wrap">{{ props.row.frizzante }}</span>
            </q-td>
            <q-td key="fredda" :props="props">
              <span class="text-wrap">{{ props.row.fredda }}</span>
            </q-td>
            <q-td key="ingresso_casetta" :props="props">
              <span class="text-wrap">{{ props.row.ingresso_casetta }}</span>
            </q-td>
            <q-td key="ingresso_acquedotto" :props="props">
              <span class="text-wrap">{{ props.row.ingresso_acquedotto }}</span>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { date } from 'quasar'

export default {
  name: 'Consumi',
  props: ['title'],
  data: () => ({
    consumi: [],
    loading: true,
    filter: {},
    pagination: {
      sortBy: 'time_stamp',
      descending: true,
      page: 1,
      rowsPerPage: 100,
      rowsNumber: 100
    },
    columns: [
      {
        name: 'time_stamp',
        align: 'left',
        label: 'DATA - ORA',
        field: 'time_stamp',
        sortable: false
      },
      {
        name: 'frizzante',
        required: true,
        label: 'FRIZZANTE',
        align: 'right',
        field: (row) => row.frizzante,
        sortable: false
      },
      {
        name: 'fredda',
        required: true,
        label: 'FREDDA',
        align: 'right',
        field: (row) => row.fredda,
        sortable: false
      },
      {
        name: 'ingresso_casetta',
        required: true,
        label: 'ING. CASETTA',
        align: 'right',
        field: (row) => row.ingresso_casetta,
        sortable: false
      },
      {
        name: 'ingresso_acquedotto',
        required: true,
        label: 'ING. ACQUEDOTTO',
        align: 'right',
        field: (row) => row.ingresso_acquedotto,
        sortable: false
      }
    ]
  }),

  mounted() {
    // get initial data from server (1st page)
    this.onRequest({
      pagination: this.pagination,
      filter: {}
    })
  },

  methods: {
    onRequest (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      let filter = { casettaId: +this.$route.params.id }
      if (this.filter.dal)
        filter.dal = this.formatDateForDb(this.filter.dal)
      if (this.filter.al)
        filter.al = this.formatDateForDb(this.filter.al)

      this.loading = true

      this.$http.post('/api/consumi/casetta', { pagination: props.pagination, filter: filter })
        .then((result) => {
          if (result.data && result.data.length) {
            this.pagination.rowsNumber = +result.data[0].full_count
            this.consumi = result.data  
            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
          } else {
            this.pagination.rowsNumber = 0
            this.consumi = []
            this.pagination.page = 0
            this.pagination.rowsPerPage = 0
          }
          this.loading = false
        })
    },

    checkDate(val) {
      return (
        date.isValid(date.extractDate(val, 'DD/MM/YYYY')) || 'Formato non valido.')
    },

    formatDateForDb(val) {
      return date.formatDate(date.extractDate(val, 'DD/MM/YYYY'), 'YYYY-MM-DD');
    },

    formatDate(val) {
      return date.formatDate(val, 'DD/MM/YYYY - HH:mm')
    }
  }
}
</script>

<style lang="sass">
.sticky-header-table
  /* height or max-height is important */
  height: 61px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: #f2f2f2
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
