<template>
  <div class="q-pt-md">
    <h6 class="q-mt-none q-mb-none">Configurazione Edge Framework</h6>
    <div class="q-pa-md">
      <div class="row">
        <div class="col" v-if="casetta">
          <q-form class="col">
            <q-input
              outlined
              dense
              size="11px"
              style="max-width: 50%; margin-bottom: 15px"
              v-model="casetta.id"
              readonly
              label="Id" />
            <q-input
              outlined
              dense
              size="11px"
              style="max-width: 50%; margin-bottom: 15px"
              v-model="casetta.protocol"
              readonly
              label="Protocol" />
            <q-input
              outlined
              dense
              size="11px"
              style="max-width: 50%; margin-bottom: 15px"
              v-model="casetta.name"
              readonly
              label="Name" />
            <q-input
              outlined
              dense
              size="11px"
              style="max-width: 50%; margin-bottom: 15px"
              v-model="casetta.description"
              readonly
              label="Description" />
            <q-input
              outlined
              dense
              size="11px"
              style="max-width: 50%; margin-bottom: 15px"
              v-model="casetta.endpoint"
              placeholder="tcp://192.168.90.112:502"
              label="EndPoint" />
            <q-input
              outlined
              dense
              size="11px"
              style="max-width: 50%; margin-bottom: 15px"
              v-model="polling"
              placeholder="30"
              label="Polling frequency" />
          </q-form>
          <q-btn
            size="11px"
            no-caps
            :disabled="!casetta.endpoint || !polling || !parametri || !parametri.length"
            color="blue-grey-2 text-black"
            label="Scrivi configurazione"
            @click="scriviCasetta()" />
        </div>
      </div>
    </div>
    <q-table
      table-header-class="bg-blue-grey-1 text-grey-8"
      :data="dati"
      :columns="columns"
      :grid="$q.screen.xs"
      :filter="filter"
      :pagination="pagination"
      row-key="channel"
      selection="multiple"
      :selected-rows-label="getSelectedString"
      :selected.sync="parametri">
    </q-table>
  </div>
</template>

<script>
export default {
  name: 'Iot',
  props: ['source'],
  data: () => ({
    casetta: undefined,
    polling: 300,
    dati: [],
    parametri: [],
    filter: '',
    pagination: {
      sortBy: 'channel',
      descending: false,
      page: 1,
      rowsPerPage: 20
    },
    columns: [
      {
        name: 'address',
        align: 'left',
        label: 'INDIRIZZO',
        field: 'address',
        sortable: true
      },
      {
        name: 'channel',
        align: 'left',
        label: 'CANALE',
        field: 'channel',
        sortable: true
      },
      {
        name: 'adt',
        align: 'left',
        label: 'TIPO DATI',
        field: 'adt',
        sortable: false
      },
      {
        name: 'data.order',
        align: 'left',
        label: 'DATA ORDER',
        field: 'data.order',
        sortable: false
      }
    ],
    standardConf: [
      {
        channel: 'azoto',
        address: '138'
      },
      {
        channel: 'cloro',
        address: '194'
      },
      {
        channel: 'colore',
        address: '170'
      },
      {
        channel: 'conducibilita',
        address: '178'
      },
      {
        channel: 'doc',
        address: '154'
      },
      {
        channel: 'ph',
        address: '186'
      },
      {
        channel: 'pressione',
        address: '218'
      },
      {
        channel: 'redox',
        address: '202'
      },
      {
        channel: 'sac254',
        address: '162'
      },
      {
        channel: 'temperatura',
        address: '210'
      },
      {
        channel: 'toc',
        address: '146'
      },
      {
        channel: 'torbidita',
        address: '130'
      }
    ]
  }),

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.$http.get('/api/v1/edge-casette').then((result) => {
        for (const cas of result.data) {
          if (cas.id === 'casetta_' + this.source.id) this.casetta = cas
        }

        if (this.casetta) { // Casetta registrata su Edge
          this.casetta.description = this.source.indirizzo

          this.$http.get('/api/v1/edge-casetta/casetta_' + this.source.id)
            .then((result) => {
              this.dati = result.data
              if (this.dati && this.dati.length)
                this.polling = this.dati[0].polling
            })
        } else { // Casetta non registrata su Edge
          this.casetta = {
            id: 'casetta_' + this.source.id,
            name: 'casetta_' + this.source.id,
            protocol: 'modbus',
            endpoint: '',
            description: this.source.indirizzo
          }

          this.dati = this.standardConf
          this.dati.forEach((c) => {
            c.adt = 'FLOAT'
            c['data.order'] = 'LSW'
          })
        }
      })

      this.$http.get('/api/parametricasetta/' + this.source.id)
        .then((result) => {
          this.parametri = result.data
        })
    },
    async scriviCasetta() {
      if (this.casetta.endpoint && this.polling && this.parametri && this.parametri.length) {
        try {
          await this.scrivi()
          try {
            await this.scriviStandardConf()
            try {
              await this.scriviParametri()
              this.init()
            } catch (err) {
              console.error('Errore scrittura parametri', err)
            }
          } catch (err) {
            console.error('Errore scrittura configurazione', err)
          }
        } catch (err) {
          console.error('Errore scrittura casetta', err)
        }
      } else {
        console.error('Dati incompleti')
      }
    },
    async scrivi() {
      return this.$http.post('/api/v1/edge-casette', {
        id: 'casetta_' + this.source.id,
        protocol: 'modbus',
        name: 'casetta_' + this.source.id,
        description: this.source.indirizzo,
        endpoint: this.casetta.endpoint
      })
    },
    async scriviStandardConf() {
      if (!this.casetta.id) return
      this.$q.loading.show()
      this.standardConf.forEach((c) => {
        c.plant = 'casetta_' + this.source.id
        c.vdt = 'FLOAT'
        c.adt = 'FLOAT'
        c.mode = 'di'
        c.polling = this.polling
        c.enabled = this.parametri.find(p => p.channel === c.channel) ? true : false
        c.thing = c.plant + '_letture'
        c['data.order'] = 'LSW'
        c['primary.table'] = 'INPUT_REGISTERS'
      })

      await this.$http.post('/api/v1/edge-casette-canali', this.standardConf)
      this.$q.loading.hide()
    },
    async scriviParametri() {
      return this.$http.post('/api/parametricasetta', {
        id: this.source.id,
        parametri: this.parametri.map(p => p.channel)
      })
    },
    getSelectedString () {
      return this.parametri.length === 0 ? '' : `Parametri selezionati: ${this.parametri.length} su ${this.dati.length}`
    }
  }
}
</script>
