<template>
  <q-chip>
    <q-avatar text-color="white" color="orange" v-if="tipo < 3">
      <q-icon name="warning" />
    </q-avatar>
    <q-avatar text-color="white" color="red" v-else>
      <q-icon name="warning" />
    </q-avatar>
    <span v-if="tipo === 0">allerta ± 1σ</span>
    <span v-if="tipo === 1">allarme ± 2σ</span>
    <span v-if="tipo === 2">emergenza ± 3σ</span>
    <span v-if="tipo === 3">critico Fuori soglia</span>
    <span v-if="tipo === 4">critico Valore non pervenuto</span>
    <span v-if="tipo === 5">critico Dati non ricevuti</span>
  </q-chip>
</template>

<script>
export default {
  name: 'TipoAllarme',
  props: ['tipo']
}
</script>
