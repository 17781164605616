import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userByDb: null,
    loading: false
  },
  mutations: {
    setToken(state, value) {
      state.token = value
    },
    setUserByDb(state, value) {
      // sessionStorage.setItem('user', value)
      state.userByDb = value
    },
    START_LOADING(state) {
      state.loading = true
    },
    END_LOADING(state) {
      state.loading = false
    },
  },
  getters: {
    getToken: (state) => {
      return state.token
    },
    getUserByDb: (state) => {
      return state.userByDb
        ? JSON.parse(state.userByDb)
        // : sessionStorage.getItem('user')
        // ? JSON.parse(sessionStorage.getItem('user'))
        : {}
    },
    getLoading: (state) => {
      return state.loading
    }
  }
})
