<template>
  <div>
    <div class="row q-mt-md">
      <div class="col-md-12 text-right">
        <q-btn
          color="light-blue-6"
          @click="addManutenzione()"
          icon="add"
          style="margin-left: 15px">
          Aggiungi Intervento
        </q-btn>
      </div>
    </div>
    <div class="q-mt-lg row inline q-gutter-md">
      <q-input
        outlined
        v-model="filter_dal"
        mask="##/##/####"
        :rules="[checkDate]"
        label="Dal"
        dense
        style="width: 150px">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              ref="qDateProxy1i"
              transition-show="scale"
              transition-hide="scale">
              <q-date v-model="filter_dal" mask="DD/MM/YYYY">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
      <q-input
        outlined
        v-model="filter_al"
        mask="##/##/####"
        :rules="[checkDate]"
        label="Al"
        dense
        style="width: 150px">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              ref="qDateProxy2i"
              transition-show="scale"
              transition-hide="scale">
              <q-date v-model="filter_al" mask="DD/MM/YYYY">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
      <q-select
        outlined
        v-model="filter_tipologia"
        :options="tipologia_options"
        label="Tipologia"
        map-options
        dense
        style="width: 200px;" />
      <q-select
        outlined
        v-model="filter_operatore"
        :options="operatore_options"
        label="Operatore"
        map-options
        dense
        style="width: 200px;" />
      <q-btn
        :disabled="!conditionSearch"
        color="light-blue-6"
        label="Cerca"
        size="12px"
        @click="search"
        style="height: 40px">
      </q-btn>
      <q-btn
        v-if="conditionSearch"
        color="blue-grey-2 text-black"
        label="Reset"
        @click="resetSearch()"
        size="12px"
        style="height: 40px">
      </q-btn>
    </div>

    <div class="q-mt-md">
      <q-table
        table-header-class="text-grey-7"
        :data="interventi"
        :columns="columns"
        row-key="data"
        :grid="$q.screen.xs"
        :pagination="pagination">
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="data" :props="props">
              {{ formatDate(props.row.data_intervento) }}
            </q-td>

            <q-td key="tipo_intervento" :props="props">
              <span class="text-wrap">{{ props.row.tipo_intervento }}</span>
            </q-td>

            <q-td key="operatore" :props="props">
              <span class="text-wrap">{{ props.row.operatore }}</span>
            </q-td>

            <q-td key="actions" :props="props">
              <q-btn
                size="11px"
                no-caps
                color="blue-grey-2 text-black"
                label="Dettagli"
                @click="openDetails(props)" />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="showDialogManutenzione" persistent square>
      <q-card class="q-pa-sm" style="width: 600px">
        <q-card-section>
          <div class="row">
            <div class="text-h6">{{ titleDialog }}</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </div>
          <div class="text-subtitle2 text-blue-grey-8">
            {{ subTitleDialog }}
          </div>
        </q-card-section>

        <q-card-section v-if="action === 'addManutenzione'">
          <div class="row q-gutter-sm">
            <q-input
              outlined
              style="max-width: 50%"
              v-model="data_intervento"
              mask="##/##/####"
              :rules="[checkDate]"
              label="Data intervento"
              dense>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy1"
                    transition-show="scale"
                    transition-hide="scale">
                    <q-date v-model="data_intervento" mask="DD/MM/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <q-input
              style="max-width: 50%; margin-bottom: 15px"
              type="time"
              outlined
              v-model="ora_intervento"
              dense
              label="Ora" />
          </div>

          <div class="column q-mt-md">
            <strong>Interventi</strong>

            <q-checkbox v-model="check_sostituzione_filtro" label="Sostituzione filtro" />
            <q-checkbox v-model="check_pulizia" label="Pulizia" />
            <q-checkbox v-model="check_sostituzione_pulsanti" label="Sostituzione pulsanti" />
            <q-checkbox v-model="check_disinfestazione" label="Disinfestazione" />
            <q-checkbox v-model="check_sanificazione" label="Sanificazione" />
            <q-checkbox v-model="check_sostituzione_lampade" label="Sostituzione lampade UV" />
            <q-checkbox v-model="check_sostituzione_adesivi" label="Sostituzione adesivi" />
            <q-checkbox v-model="check_sostituzione_lettore_crs" label="Sostituzione lettore CRS" />

            <q-select
              clearable
              outlined
              dense
              style="max-width: 75%; margin-top:10px; margin-bottom: 15px"
              v-model="manutenzione_lettore_crs"
              :options="manutenzione_lettore_crsOptions"
              label="Manutenzione lettore CRS"
              map-options />

            <q-select
              clearable
              outlined
              dense
              style="max-width: 75%; margin-bottom: 15px"
              v-model="interventi_atti_vandalici"
              :options="interventi_atti_vandaliciOptions"
              label="Interventi dovuti ad atti vandalici"
              map-options />

            <q-input
              style="max-width: 30%; margin-bottom: 15px"
              type="number"
              outlined
              v-model="num_bombole_sostituite"
              dense
              label="Bombole sostituite" />

            <q-input
              style="max-width: 30%; margin-bottom: 15px"
              type="number"
              outlined
              v-model="num_bombole_rimosse"
              dense
              label="Bombole rimosse" />

            <q-input
              style="max-width: 30%; margin-bottom: 15px"
              type="number"
              outlined
              v-model="quantita_co2"
              dense
              label="Quantità di CO2" />

            <q-input
              style="max-width: 30%; margin-bottom: 15px"
              type="number"
              outlined
              v-model="pet_risparmiati"
              dense
              label="Kg PET risparmiati" />

            <q-input
              style="max-width: 30%; margin-bottom: 15px"
              type="number"
              outlined
              v-model="durata_intervento"
              dense
              label="Durata intevento" />

            <q-input
              style="max-width: 100%; margin-bottom: 15px; max-height: 100px"
              type="textarea"
              outlined
              v-model="note"
              dense
              label="Note" />
          </div>
        </q-card-section>

        <q-card-section v-if="action === 'readManutenzione'">
          <div class="q-py-md">
            <q-list bordered separator>
              <q-item>
                <q-item-section>
                  <q-item-label>Durata intervento</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    {{ elemCurr.durata_intervento }} ore
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Sostituzione filtro</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    <q-icon
                      v-if="elemCurr.sostituzione_filtro"
                      name="checked"
                      color="green"
                      size="24px">
                    </q-icon>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Pulizia</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    <q-icon
                      v-if="elemCurr.pulizia"
                      name="checked"
                      color="green"
                      size="24px">
                    </q-icon>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Sostituzione pulsanti</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    <q-icon
                      v-if="elemCurr.sostituzione_pulsanti"
                      name="checked"
                      color="green"
                      size="24px">
                    </q-icon>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Disinfestazione</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    <q-icon
                      v-if="elemCurr.disinfestazione"
                      name="checked"
                      color="green"
                      size="24px">
                    </q-icon>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Sanificazione</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    <q-icon
                      v-if="elemCurr.sanificazione"
                      name="checked"
                      color="green"
                      size="24px">
                    </q-icon>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Sostituzione lampade UV</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    <q-icon
                      v-if="elemCurr.sostituzione_lampade_uv"
                      name="checked"
                      color="green"
                      size="24px">
                    </q-icon>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Sostituzione adesivi</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    <q-icon
                      v-if="elemCurr.sostituzione_adesivi"
                      name="checked"
                      color="green"
                      size="24px">
                    </q-icon>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Sostituzione lettore CRS</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    <q-icon
                      v-if="elemCurr.sostituzione_lettore_crs"
                      name="checked"
                      color="green"
                      size="24px">
                    </q-icon>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Manutenzione lettore CRS</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    {{ elemCurr.manutenzione_lettore_crs }}
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Interventi dovuti ad atti vandalici</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    {{ elemCurr.interventi_atti_vandalici }}
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Bombole sostituite</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label v-if="elemCurr.bombole_sostituite > 0">
                    {{ elemCurr.bombole_sostituite }}
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Bombole rimosse</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label v-if="elemCurr.bombole_rimosse > 0">
                    {{ elemCurr.bombole_rimosse }}
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>
                    Quantità di CO2 in funzione del volume erogato
                  </q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label v-if="elemCurr.quantita_co2 > 0">
                    {{ elemCurr.quantita_co2 }}
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Kg PET risparmiati</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label v-if="elemCurr.pet_risparmiati > 0">
                    {{ elemCurr.pet_risparmiati }}
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced></q-separator>

              <q-item>
                <q-item-section>
                  <q-item-label>Note</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>
                    {{ elemCurr.note }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-card-section>

        <q-card-actions
          v-if="action !== 'readManutenzione'"
          class="q-pr-md q-pb-md q-mt-md"
          align="right">
          <q-btn flat label="Annulla" v-close-popup />
          <q-btn
            :label="dialoglabel"
            color="light-blue-6"
            @click="dialogAction"
            v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { date } from 'quasar'

export default {
  name: 'Interventi',
  data: () => ({
    interventi: [],
    interventiOriginal: [],
    pagination: {
      sortBy: 'data',
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: 'data',
        align: 'left',
        label: 'DATA',
        field: 'data_intervento',
        format: (val) => date.formatDate(val, 'DD/MM/YYYY HH:mm:ss'),
        sortable: true
      },
      {
        name: 'tipo_intervento',
        required: true,
        label: 'TIPOLOGIA',
        align: 'left',
        field: (row) => row.tipo_intervento,
        sortable: true
      },
      {
        name: 'operatore',
        required: true,
        label: 'OPERATORE',
        align: 'left',
        field: (row) => row.operatore,
        sortable: true
      },
      {
        name: 'actions',
        align: 'right',
        label: 'AZIONI'
      }
    ],
    showDialogManutenzione: false,
    titleDialog: '',
    action: null,
    data_intervento: null,
    ora_intervento: null,
    check_sostituzione_filtro: false,
    check_pulizia: false,
    check_sostituzione_pulsanti: false,
    check_disinfestazione: false,
    check_sanificazione: false,
    check_sostituzione_lampade: false,
    num_bombole_sostituite: 0,
    num_bombole_rimosse: 0,
    durata_intervento: 0,
    note: null,
    subTitleDialog: '',
    dialoglabel: '',
    elemCurr: {},
    filter_dal: null,
    filter_al: null,
    filter_tipologia: null,
    tipologia_options: [
      {
        label: 'Manutenzione',
        value: 'manutenzione'
      },
      {
        label: 'Rilevamento',
        value: 'rilevamento'
      }
    ],
    filter_operatore: null,
    operatore_options: [],
    check_sostituzione_lettore_crs: false,
    manutenzione_lettore_crs: '',
    manutenzione_lettore_crsOptions: [
      {
        label: 'Sostituzione scheda',
        value: 'Sostituzione scheda'
      },
      {
        label: 'Riparazione',
        value: 'Riparazione'
      }
    ],
    interventi_atti_vandalici: '',
    interventi_atti_vandaliciOptions: [
      {
        label: 'Rimozione vernice',
        value: 'Rimozione vernice'
      },
      {
        label: 'Sostituzione vetro',
        value: 'Sostituzione vetro'
      }
    ],
    check_sostituzione_adesivi: false,
    quantita_co2: 0,
    pet_risparmiati: 0
  }),

  computed: {
    getUser() {
      return this.$store.getters.getUserByDb.email
    },

    conditionSearch() {
      return (
        this.filter_dal ||
        this.filter_al ||
        this.filter_tipologia ||
        this.filter_operatore
      )
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      this.$http
        .get('/api/secure/interventi/all', {
          params: { casettaId: this.$route.params.id },
        })
        .then((result) => {
          this.interventi = result.data
          this.interventiOriginal = this.interventi
        })

      this.$http.get('/api/secure/users/all', {})
        .then((results) => {
          this.operatore_options = results.data.map(u => { return { label: u.email, value: u.email } })
        })
    },

    addManutenzione() {
      this.showDialogManutenzione = true
      this.titleDialog = 'Inserisci Manutenzione'
      this.subTitleDialog = 'Operatore: ' + this.getUser
      this.action = 'addManutenzione'
      this.dialoglabel = 'Salva'
    },

    dialogAction() {
      if (this.action === 'addManutenzione')
        this.salvaManutenzione()
    },

    extractData(val) {
      return date.extractDate(val, 'DD/MM/YYYY HH:mm')
    },

    checkDate(val) {
      return (
        date.isValid(date.extractDate(val, 'DD/MM/YYYY')) || 'Formato non valido.'
      )
    },

    formatDate(val) {
      return date.formatDate(val, 'DD/MM/YYYY - HH:mm')
    },

    formatDateSearch(val) {
      let newData = date.formatDate(val, 'DD/MM/YYYY')
      return date.extractDate(newData, 'DD/MM/YYYY')
    },

    formatDateSearchFilter(val) {
      return date.extractDate(val, 'DD/MM/YYYY')
    },

    salvaManutenzione() {
      let data_intera = this.extractData(
        this.data_intervento + ' ' + this.ora_intervento
      )

      let toSave = {
        casetta_id: this.$route.params.id,
        operatore: this.getUser,
        tipo_intervento: 'manutenzione',
        data_intervento: data_intera,
        sostituzione_filtro: this.check_sostituzione_filtro,
        pulizia: this.check_pulizia,
        sostituzione_pulsanti: this.check_sostituzione_pulsanti,
        disinfestazione: this.check_disinfestazione,
        sanificazione: this.check_sanificazione,
        sostituzione_lampade_uv: this.check_sostituzione_lampade,
        bombole_sostituite: this.num_bombole_sostituite,
        bombole_rimosse: this.num_bombole_rimosse,
        durata_intervento: this.durata_intervento,
        note: this.note,
        sostituzione_lettore_crs: this.sostituzione_lettore_crs,
        manutenzione_lettore_crs: this.manutenzione_lettore_crs
          ? this.manutenzione_lettore_crs.value
          : '',
        interventi_atti_vandalici: this.interventi_atti_vandalici
          ? this.interventi_atti_vandalici.value
          : '',
        sostituzione_adesivi: this.sostituzione_adesivi,
        quantita_co2: this.quantita_co2,
        pet_risparmiati: this.pet_risparmiati
      }

      this.$http.post('/api/secure/intervento/save', toSave, { })
        .then(() => {
          this.$q.notify({
            message: 'Salvataggio riuscito',
            color: 'green',
            timeout: 1000
          })
          this.getData()
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Errore durante il salvataggio',
            color: 'red',
            timeout: 1000
          })
          console.log('Errore durante il salvataggio', err)
        })
    },

    openDetails(prop) {
      this.showDialogManutenzione = true
      this.titleDialog =
        'Manutenzione del ' + this.formatDate(prop.row.data_intervento)
      this.subTitleDialog = 'Operatore: ' + prop.row.operatore
      this.action = 'readManutenzione'
      this.elemCurr = JSON.parse(JSON.stringify(prop.row))
    },

    search() {
      /* console.log(
        'ricerca filter: ',
        this.filter_dal,
        this.filter_al,
        this.filter_tipologia,
        this.filter_operatore
      ) */

      let filtersSelected = []
      let filters = {
        dal: (item) => {
          return (
            this.formatDateSearch(item.data_intervento) >=
            this.formatDateSearchFilter(this.filter_dal)
          )
        },
        al: (item) => {
          return (
            this.formatDateSearch(item.data_intervento) <=
            this.formatDateSearchFilter(this.filter_al)
          )
        },
        tipo: (item) => {
          return item.tipo_intervento === this.filter_tipologia.value
        },
        operatore: (item) => {
          return item.operatore === this.filter_operatore.value
        },
      }

      if (this.filter_dal) filtersSelected.push(filters.dal)
      if (this.filter_al) filtersSelected.push(filters.al)
      if (this.filter_tipologia) filtersSelected.push(filters.tipo)
      if (this.filter_operatore) filtersSelected.push(filters.operatore)

      this.interventi = this.interventiOriginal.filter((item) => {
        let index = 0
        filtersSelected.forEach((f) => {
          if (f(item)) index++
        })
        if (index === filtersSelected.length) return item
      })
    },

    resetSearch() {
      this.filter_dal = null
      this.filter_al = null
      this.filter_tipologia = null
      this.filter_operatore = null

      this.interventi = this.interventiOriginal
    }
  }
}
</script>

<style></style>
