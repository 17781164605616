<template>
  <div class="q-mx-lg">
    <div class="row q-mt-xl q-mb-xl">
      <div class="col-12 col-md-12">
        <h5 class="q-ma-none text-bold">Censimento Anagrafiche</h5>
      </div>
    </div>
    <q-tabs
      v-model="tab"
      dense
      class="text-grey"
      active-color="light-blue-6"
      indicator-color="light-blue-6"
      align="left"
      narrow-indicator>
      <q-tab name="proprietari" label="Proprietari" />
      <q-tab name="produttori" label="Produttori" />
      <q-tab name="manutentori" label="Manutentori" />
      <q-tab name="comuni" label="Comuni" />
      <q-tab name="serie" label="Serie casette" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="proprietari">
        <template-anagrafiche tipo="proprietario"></template-anagrafiche>
      </q-tab-panel>

      <q-tab-panel name="produttori">
        <template-anagrafiche tipo="produttore"></template-anagrafiche>
      </q-tab-panel>

      <q-tab-panel name="manutentori">
        <template-anagrafiche tipo="manutentore"></template-anagrafiche>
      </q-tab-panel>

      <q-tab-panel name="comuni">
        <template-anagrafiche tipo="comune"></template-anagrafiche>
      </q-tab-panel>

      <q-tab-panel name="serie">
        <template-anagrafiche tipo="serie"></template-anagrafiche>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import TemplateAnagrafiche from '../components/TemplateAnagrafiche'
export default {
  name: 'Anagrafiche',
  components: {
    TemplateAnagrafiche
  },
  data: () => ({
    tab: 'proprietari',
    casetta: null
  })
}
</script>
