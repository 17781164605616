import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './quasar'
import Axios from 'axios'
import _ from 'lodash'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(VueApexCharts)

Vue.$cookies.config('30d')

Vue.component('apexchart', VueApexCharts)

const http = Axios.create()

http.interceptors.request.use(
  (config) => {
    store.commit('START_LOADING')
    return config
  },
  (error) => {
    store.commit('END_LOADING')
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    store.commit('END_LOADING')
    return response
  },
  (error) => {
    store.commit('END_LOADING')
    return Promise.reject(error)
  }
)

Vue.prototype.$http = http
Vue.prototype.$http2 = Axios
Vue.prototype.$_ = _

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  http.get('/api/current-user', {})
    .then((results) => {
      // console.log('/api/current-user', results.data)
      store.commit('setUserByDb', JSON.stringify(results.data))
      next()
    })
    .catch((err) => {
      console.log('ERR /api/current-user', err)
      store.commit('setUserByDb', null)
      if (to.name !== 'Dashboard')
        next({ name: 'Dashboard' })
      else
        next()
    })
  // console.log('router.beforeEach', to, from, next)
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
