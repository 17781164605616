<template>
  <q-layout view="hHh lpR fFf">
    <q-header elevated class="bg-blue-grey-2 text-black" v-if="getAuth">
      <q-toolbar>
        <div id="apriMenu">
          <q-btn dense flat round icon="menu" @click="left = !left" />
        </div>

        <q-toolbar-title>
          <router-link class="linkClick" to="/dashboard">
            <img class="logo" src="./assets/logo.png" />
          </router-link>
        </q-toolbar-title>
        <q-btn v-if="getUserByDb" round style="margin-right: 5px">
          <q-avatar
            flat
            color="white"
            text-color="grey-7"
            icon="person">
          </q-avatar>
        </q-btn>

        <q-btn-dropdown
          v-if="getUserByDb"
          flat
          no-caps
          :label="getUserByDb.nome"
          style="margin-right: 10px">
          <q-list>
            <q-item clickable v-close-popup @click="logout">
              <q-item-section>
                <q-item-label>Log out</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <q-drawer id="menu" show-if-above v-model="left" side="left" bordered>
      <q-item class="q-mt-md" clickable to="/dashboard">
        <q-item-section avatar>
          <q-icon name="dashboard" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Dashboard</q-item-label>
        </q-item-section>
      </q-item>

      <q-item v-if="getAuth('casette')" clickable to="/casette">
        <q-item-section avatar>
          <q-icon name="home" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Casette</q-item-label>
        </q-item-section>
      </q-item>

      <q-list id="menuList" v-if="getAuth('statistiche')">
        <q-expansion-item icon="bar_chart" label="Report e Statistiche">
          <q-item :inset-level="1" clickable to="/download" class="q-mt-sm">
            <q-item-label>Download Report</q-item-label>
          </q-item>

          <q-item :inset-level="1" clickable to="/risparmio">
            <q-item-label>Risparmio Energetico</q-item-label>
          </q-item>

          <q-item :inset-level="1" clickable to="/qualita">
            <q-item-label>Qualità dell'acqua</q-item-label>
          </q-item>
        </q-expansion-item>
      </q-list>

      <q-separator />

      <q-list v-if="getAuth('impostazioni')">
        <q-expansion-item icon="settings" label="Impostazioni">
          <q-item :inset-level="1" clickable to="/regole" class="q-mt-sm">
            <q-item-label>Parametri qualità dell'acqua</q-item-label>
          </q-item>
          <q-item :inset-level="1" clickable to="/anagrafiche">
            <q-item-label>Censimento anagrafiche</q-item-label>
          </q-item>
          <q-item :inset-level="1" clickable to="/operatoriAllerte">
            <q-item-label>Operatori per notifiche Alert</q-item-label>
          </q-item>
        </q-expansion-item>
        <q-item v-if="getAuth('utenti')" clickable to="/ruoli">
          <q-item-section avatar>
            <q-icon name="people" />
          </q-item-section>
          <q-item-section
            class="flex justify-between"
            style="flex-direction: row; margin-top: 8px">
            <q-item-label>Utenti e Ruoli</q-item-label>
            <div role="alert" style="margin-top: -4px">
              <q-badge v-if="newUsers === 1" color="grey">
                1 nuovo
              </q-badge>
              <q-badge v-if="newUsers > 1" color="grey">
                {{ newUsers }} nuovi
              </q-badge>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-linear-progress v-if="isLoading" indeterminate />
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'App',

  components: {},
  data: () => ({
    left: false,
    newUsers: 0,
  }),
  computed: {
    getUserByDb() {
      return this.$store.getters.getUserByDb
    },
    isLoading() {
      return this.$store.getters.getLoading
    },
    getUsername() {
      return this.$cookies.get('name')
    },
    getEmail() {
      return this.$cookies.get('email')
    },
  },
  /* mounted() {
    this.getUserByEmail()
  }, */
  methods: {
    /* getUserByEmail() {
      if (this.getEmail) {
        console.log('questa mail by cookie', this.getEmail)
        this.$http
          .get('/api/secure/user/get-email', {
            params: { email: this.getEmail }
          })
          .then((results) => {
            this.$store.commit('setUserByDb', JSON.stringify(results.data))

            if (this.getUserByDb.ruoli !== []) this.getNewUsers()
          })
      } else {
        console.error('nessuna mail by cookie')
      }
    }, */
    logout() {
      this.$http.get('/logout', {})
        .then((result) => {
          this.$store.commit('setUserByDb', null)
          window.location.href = result.data.url
        })
    },

    getNewUsers() {
      this.$http.get('/api/secure/user/check', {})
        .then((results) => {
          this.newUsers = results.data.count
        })
    },

    getAuth(type) {
      let flag = false

      if (!this.getUserByDb) return flag

      switch (type) {
        case 'casette':
          if (
            this.$_.includes(this.getUserByDb.ruoli, 'admin') ||
            this.$_.includes(this.getUserByDb.ruoli, 'casette') ||
            this.$_.includes(this.getUserByDb.ruoli, 'operatore')
          )
            flag = true
          break
        case 'statistiche':
          if (
            this.$_.includes(this.getUserByDb.ruoli, 'admin') ||
            this.$_.includes(this.getUserByDb.ruoli, 'statistiche')
          )
            flag = true
          break
        case 'impostazioni':
          if (
            this.$_.includes(this.getUserByDb.ruoli, 'admin') ||
            this.$_.includes(this.getUserByDb.ruoli, 'impostazioni')
          )
            flag = true
          break
        case 'utenti':
          if (this.$_.includes(this.getUserByDb.ruoli, 'admin')) flag = true
          break
      }
      return flag
    }
  }
}
</script>

<style>
.logo {
  height: 50px;
  margin-top: 10px;
}
.avatar {
  font-size: 30px;
}
.linkClick:active {
  position: relative;
  top: 1px;
  left: 1px;
}
</style>
