<template>
  <div class="q-mx-lg" v-if="!loading">
    <div class="row q-mt-md q-mb-xl">
      <div class="col col-md-8">
        <q-btn
          align="around"
          flat
          color="light-blue-6"
          padding="sm none"
          size="sm"
          label="Elenco Case dell'Acqua"
          icon="keyboard_arrow_left"
          to="/casette" />
        <h5 class="q-ma-none text-bold">{{ getTitle }}</h5>
        <p v-if="getSubTitle" class="q-ma-none">{{ getSubTitle }}</p>
      </div>
      <div class="col col-md-4 text-right q-mt-lg">
        <div v-if="getStatoColor">
          <q-chip
            text-color="white"
            :color="getStatoColor"
            :label="getStatoLabel" />
        </div>
      </div>
    </div>

    <q-tabs
      v-if="!isNew"
      v-model="tab"
      dense
      class="text-grey"
      active-color="light-blue-6"
      indicator-color="light-blue-6"
      align="left"
      narrow-indicator>
      <q-tab name="informazioni" label="Informazioni" />
      <q-tab name="documenti" label="Documenti" />
      <q-tab name="interventi" label="Interventi" />
      <q-tab name="consumi" label="Telelettura consumi" />
      <q-tab name="letture" label="Qualità dell'acqua" />
      <q-tab name="operatori" label="Operatori" />
      <q-tab name="allarmi">
         <q-chip style="background: transparent;" class="text-grey">
            <q-avatar
              size="xs"
              text-color="white"
              :color="casetta.allarme_attivo > 2 ? 'red' : 'orange'"
              v-if="casetta && casetta.allarme_attivo !== null">
              <q-icon name="warning" />
            </q-avatar>
            <span>Allarmi</span>
         </q-chip>
      </q-tab>
      <q-tab name="iot" label="Iot" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-if="!isNew" v-model="tab" animated>
      <q-tab-panel name="informazioni">
        <informazioni :source="casetta" @refresh="getData"></informazioni>
      </q-tab-panel>

      <q-tab-panel name="documenti">
        <documenti :source="casetta"></documenti>
      </q-tab-panel>

      <q-tab-panel name="interventi">
        <interventi :source="casetta"></interventi>
      </q-tab-panel>

      <q-tab-panel name="consumi">
        <consumi :title="getTitle"></consumi>
      </q-tab-panel>

      <q-tab-panel name="letture">
        <letture :title="getTitle"></letture>
      </q-tab-panel>

      <q-tab-panel name="operatori">
        <operatori :source="casetta"></operatori>
      </q-tab-panel>

      <q-tab-panel name="allarmi">
        <allarmi :source="casetta"></allarmi>
      </q-tab-panel>

      <q-tab-panel name="iot">
        <iot :source="casetta" @refresh="getData"></iot>
      </q-tab-panel>
    </q-tab-panels>

    <div v-if="isNew">
      <informazioni :source="null" @refresh="getData"></informazioni>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar'
import Informazioni from '../components/Informazioni'
import Documenti from '../components/Documenti'
import Interventi from '../components/Interventi'
import Operatori from '../components/Operatori'
import Allarmi from '../components/Allarmi'
import Letture from '../components/Letture'
import Consumi from '../components/Consumi'
import Iot from '../components/Iot'

export default {
  name: 'Casetta',
  components: {
    Informazioni,
    Documenti,
    Interventi,
    Operatori,
    Allarmi,
    Letture,
    Consumi,
    Iot
  },
  data: () => ({
    tab: 'informazioni',
    casetta: null,
    loading: false
  }),

  computed: {
    isNew() {
      return this.$route.params.id === 'new' ? true : false
    },

    getTitle() {
      return this.isNew
        ? 'Aggiungi casetta'
        : this.casetta
          ? this.casetta.indirizzo
          : ''
    },

    getSubTitle() {
      return this.isNew
        ? ''
        : this.casetta
          ? this.casetta.indicazioni_aggiuntive
          : ''
    },

    getStatoColor() {
      let obj = null
      if (this.casetta) {
        switch (this.casetta.stato) {
          case 'attiva':
            obj = 'green'
            break
          case 'chiusa':
            obj = 'red'
            break
          case 'da attivare':
            obj = 'grey'
            break
          case 'in manutenzione':
            obj = 'orange'
            break
        }
      }
      return obj
    },

    getStatoLabel() {
      let str = null
      if (this.casetta) {
        str = this.$_.capitalize(this.casetta.stato)
      }
      return str
    }
  },

  mounted() {
    if (!this.isNew) this.getData()
  },

  watch: {
    isNew() {
      this.getData()
    }
  },

  methods: {
    formatDate(val) {
      return date.formatDate(val, 'DD/MM/YYYY')
    },

    getData() {
      this.loading = true
      this.$http
        .get('/api/v1/casetta-info', {
          params: { casettaId: this.$route.params.id }
        })
        .then((result) => {
          if (result.data && result.data.length) {
            this.casetta = result.data[0]
            this.casetta.data_inaugurazione = this.casetta.data_inaugurazione
              ? this.formatDate(this.casetta.data_inaugurazione)
              : null
            this.casetta.data_scadenza_contratto = this.casetta.data_scadenza_contratto
              ? this.formatDate(this.casetta.data_scadenza_contratto)
              : null
            this.casetta.last_update = this.casetta.updatedAt
              ? this.formatDate(this.casetta.updatedAt)
              : null

            this.$http.get('/api/letture/casetta/has_allarmi/' + this.$route.params.id)
              .then((result) => {
                this.$set(this.casetta, 'has_allarmi', result.data.has_allarmi)
                this.$set(this.casetta, 'allarme_attivo', result.data.allarme_attivo)
              })
          }

          this.loading = false
        })
    }
  }
}
</script>
