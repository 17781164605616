<template>
  <div class="q-mx-lg">
    <div class="row q-mt-xl q-mb-xl">
      <div class="col-12 col-md-12">
        <h5 class="q-ma-none text-bold">Qualità dell'acqua</h5>
      </div>
    </div>
    <q-form @submit="getData" greedy ref="form">
      <div class="row inline q-gutter-md">
        <q-select
          outlined
          dense
          style="max-width: 350px; width: 400px;"
          label="Seleziona Casa dell'Acqua"
          class="c-casetta"
          v-model="casetta"
          @input="selCasetta"
          :options="casette"
          :rules="[val => !!val || '* Obbligatorio']"
          lazy-rules
          map-options />
        <q-select
          outlined
          dense
          style="max-width: 250px;"
          v-model="parametro"
          :options="parametriCasetta"
          label="Parametro"
          :rules="[val => !!val || '* Obbligatorio']"
          lazy-rules
          map-options />
        <q-input ng-if="casetta && casetta.navmin"
          outlined
          dense
          style="width: 150px"
          v-model="dal"
          mask="##/##/####"
          :rules="[checkDate]"
          label="Dal">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy1"
                transition-show="scale"
                transition-hide="scale">
                <q-date
                  v-model="dal"
                  :navigation-min-year-month="casetta.navmin"
                  :navigation-max-year-month="casetta.navmax"
                  default-view="Months"
                  mask="DD/MM/YYYY">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input ng-if="casetta && casetta.navmin"
          outlined
          dense
          style="width: 150px"
          v-model="al"
          mask="##/##/####"
          :rules="[checkDate]"
          label="Al">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy2"
                transition-show="scale"
                transition-hide="scale">
                <q-date
                  v-model="al"
                  :navigation-min-year-month="casetta.navmin"
                  :navigation-max-year-month="casetta.navmax"
                  default-view="Months"
                  mask="DD/MM/YYYY">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input
          outlined
          dense
          v-model.number="periodo"
          type="number"
          label="Periodo (in gg)"
          :rules="[val => !!val || '* Obbligatorio']"
          style="max-width: 110px;" />
        <q-btn
          color="light-blue-6"
          label="Visualizza"
          type="submit"
          @click="checkIsDirty"
          style="height: 38px;">                 
        </q-btn>
        <q-btn
          v-if="dati && dati.length"
          color="green"
          label="Scarica xls"
          @click="exportXls"
          style="height: 38px;">
        </q-btn>
      </div>
    </q-form>

    <apexchart
      v-if="dati && dati.length"
      ref="chart"
      type="line"
      height="400"
      width="100%"
      class="q-mt-lg"
      :options="chartOptions"
      :series="series">
    </apexchart>

    <div v-if="!dati || !dati.length">
      <q-banner inline-actions rounded class="bg-amber-1 text-grey-8">
        Non ci sono dati per generare un grafico. Riprova.
      </q-banner>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar'
import { utils, writeFile } from 'xlsx'

export default {
  name: 'QualitaReport',
  data: () => ({
    casetta: {},
    periodo: 7,
    casette: [],
    dati: [],
    series: [],
    parametro: null,
    parametri: [],
    parametriCasetta: [],
    dal: null,
    al: null,
    body: null,
    flagVisualizza: false,
    chartOptions: {
      chart: {
        id: 'chartid2',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        },
        toolbar: {
          show: true,
          autoSelected: 'zoom',
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          }
        }
      },
      yaxis: {
        title: { text: 'Parametro' },
        decimalsInFloat: 3
      },
      xaxis: {
        type: 'datetime',
        tooltip: {
          formatter: function (val) {
            const dt = new Date(val)
            const options = {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
            return dt.toLocaleDateString('it-IT', options)
          }
        },
        labels: {
          formatter: function (val, timestamp) {
            const dt = new Date(timestamp)
            const options = {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }
            return dt.toLocaleDateString('it-IT', options)
          }
        }
      },
      dataLabels: { enabled: false },
      title: { text: 'Parametro', align: 'left' },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return val.toFixed(2)
          }
        }
      },
      stroke: {
        width: [1, 1, 1, 5, 5, 1, 1, 1, 1, 1],
        curve: 'smooth'
        //dashArray: [8, 8, 8, 0, 0, 8, 8, 8]
      },
      colors: [
        '#E91E63',
        '#FF9800',
        '#546E7A',
        '#2E93fA',
        '#66DA26',
        '#546E7A',
        '#FF9800',
        '#E91E63',
        '#2E294E',
        '#2E294E'
      ]
    }
  }),

  mounted() {
    this.getCasette()
    this.dati = []
    this.series = []
  },

  methods: {
    checkDate(val) {
      return (
        date.isValid(date.extractDate(val, 'DD/MM/YYYY')) || 'Formato non valido.'
      )
    },

    formatDate(val) {
      return date.formatDate(val, "DD/MM/YYYY")
    },

    formatDateForDb(val) {
      if (!val) return null
      return date.formatDate(date.extractDate(val, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    },

    getCasette() {
      this.$http.get('/api/secure/casette/all', {})
        .then((results) => {
          let data = results.data
          this.$_.forEach(data, (el) => {
            let obj = {}
            obj.value = el.id
            obj.label = el.indirizzo +
              (el.indicazioni_aggiuntive
                ? ' - ' + el.indicazioni_aggiuntive
                : '')

            this.casette.push(obj)
          })

          this.casetta = this.casette[0]
          this.selCasetta(this.casetta)
        })

      if (!this.parametri || !this.parametri.length)
        this.$http.get('/api/secure/regole/all', {})
          .then((result) => {
            this.parametri = result.data
            this.parametri.forEach((p) => {
              p.label = p.parametro
              p.value = p.id
            })
            this.parametro = this.parametri[0]
          })
    },

    SMA(array, window = 5) {
      const SMAs = []

      if (array && array.length) {
        for (let idx = 0; idx < array.length; idx++) {
          const start = Math.max(0, idx - window + 1)
          const windowSlice = array.slice(start, idx + 1)
          const sum = windowSlice.reduce((prev, curr) => prev + curr, 0)
          SMAs.push(sum / windowSlice.length)
        }
      }

      return SMAs
    },

    stdv(array) {
      const n = array.length
      const mean = array.reduce((a, b) => a + b) / n
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      )
    },

    getData() {
      this.body = {
        id: this.casetta.value,
        from: this.formatDateForDb(this.dal),
        to: this.formatDateForDb(this.al),
        par: this.parametro.value
      }

      this.$http.post('/api/letture/qualita', this.body).then((result) => {
        // A un certo punto diventa un Array, non ho capito perché ????
        if (Array.isArray(this.chartOptions.yaxis)) {
          this.chartOptions.yaxis[0].title.text = this.parametro.label
        } else {
          this.chartOptions.yaxis.title.text = this.parametro.label
        }
        this.chartOptions.title.text = this.parametro.label

        this.dati = result.data
        if (result.data.length) {
          this.body.casetta = this.casetta.label
          this.series = []
          this.series[3] = {
            name: this.parametro.label,
            data: this.dati.map((a) => a.value),
          }
          let smas = this.SMA(this.series[3].data, this.periodo)
          let sigma = this.stdv(this.series[3].data)
          this.series[4] = { name: 'SMA', data: smas }
          this.series[2] = {
            name: 'allerta+',
            data: smas.map((a) => a + sigma)
          }
          this.series[5] = {
            name: 'allerta-',
            data: smas.map((a) => a - sigma)
          }
          this.series[1] = {
            name: 'allarme+',
            data: smas.map((a) => a + sigma * 2)
          }
          this.series[6] = {
            name: 'allarme-',
            data: smas.map((a) => a - sigma * 2)
          }
          this.series[0] = {
            name: 'emergenza+',
            data: smas.map((a) => a + sigma * 3)
          }
          this.series[7] = {
            name: 'emergenza-',
            data: smas.map((a) => a - sigma * 3)
          }
          this.chartOptions.xaxis.categories = this.dati.map((a) =>
            new Date(a.data).getTime()
          )

          if (this.parametro.min !== null) {
            let dati = new Array(smas.length).fill(this.parametro.min)
            this.series[this.series.length] = {
              name: 'critico-',
              data: dati
            }
          }

          if (this.parametro.max !== null) {
            let dati = new Array(smas.length).fill(this.parametro.max)
            this.series[this.series.length] = {
              name: 'critico+',
              data: dati
            }
          }

          if (this.$refs.chart) {
            this.$refs.chart.updateSeries(this.series)
            this.$refs.chart.updateOptions(this.chartOptions, true)
          }
        }
      })
    },

    async checkIsDirty() {
      let res = await this.$refs.form.validate()
      if (!res) {
        this.$q.notify({
          type: 'negative',
          message: 'Alcuni campi non sono stati compilati.'
        })
      }
    },

    setRange(min, max) {
      this.dal = this.formatDate(min)
      this.al = this.formatDate(max)
      this.casetta.navmin = min.substring(0, 7).replace(/-/g, '/')
      this.casetta.navmax = max.substring(0, 7).replace(/-/g, '/')
    },

    selCasetta() {
      if (this.casetta.navmin && this.casetta.navmax)
        this.setRange(this.casetta.navmin, this.casetta.navmax)
      else
        this.getRange()

      if (this.casetta.parametri)
        this.parametriCasetta = this.parametri.filter(p => this.casetta.parametri.find(c => p.id===c.channel))
      else
        this.$http.get('/api/parametricasetta/' + this.casetta.value)
          .then((result) => {
            this.casetta.parametri = result.data
            this.parametriCasetta = this.parametri.filter(p => this.casetta.parametri.find(c => p.id===c.channel))
          })
    },

    exportXls() {
      this.dati.forEach((a) => a.data = new Date(a.data))
      let wb = utils.book_new()
      let ws = utils.json_to_sheet(this.dati, { origin: 'A2', skipHeader: true, cellDates: true, dateNF: 'DD/MM/YYYY' })
      ws['!cols'] = [{ wch: 10 }, { wch: Math.max(this.chartOptions.title.text.length, 7) }]
      utils.sheet_add_aoa(ws, [["Data", this.chartOptions.title.text]])
      utils.book_append_sheet(wb, ws, this.body.casetta)
      writeFile(wb, 'export.xlsx')
    },

    getRange() {
      this.showRange = false
      this.$http.get('/api/letture/range/' + this.casetta.value)
        .then((result) => {
          if (result.data.data_min) {
            this.casetta.navmin = result.data.data_min
            this.casetta.navmax = result.data.data_max
            this.setRange(result.data.data_min, result.data.data_max)
          } else {
            delete this.casetta.navmin
            delete this.casetta.navmax
          }
        })
    }
  }
}
</script>

<style>
.q-table--no-wrap th,
.q-table--no-wrap td .text-wrap {
  white-space: normal;
}
@media screen and (max-width: 1750px) {
  .q-table--no-wrap th,
  .q-table--no-wrap td {
    white-space: normal !important;
  }
}
.c-casetta span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
