<template>
  <div>
    <div class="q-mt-md">
      <q-table
        table-header-class="text-grey-7"
        class="sticky-header-table"
        style="height: 65vh"
        virtual-scroll
        dense
        title="Allarmi"
        :data="allarmi"
        :columns="columns"
        row-key="id"
        :grid="$q.screen.xs"
        :loading="loading"
        :filter="filter"
        :pagination.sync="pagination"
        :rows-per-page-options="[]"
        @request="onRequest"
        binary-state-sort>
        <template v-slot:top-right>
          <q-input
            outlined
            v-model="filter.dal"
            mask="##/##/####"
            :rules="[checkDate]"
            label="Dal"
            dense
            class="q-ml-md q-mt-md"
            style="width: 150px">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy1i"
                  transition-show="scale"
                  transition-hide="scale">
                  <q-date v-model="filter.dal" mask="DD/MM/YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-input
            outlined
            v-model="filter.al"
            mask="##/##/####"
            :rules="[checkDate]"
            label="Al"
            dense
            class="q-ml-md q-mt-md"
            style="width: 150px">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy2i"
                  transition-show="scale"
                  transition-hide="scale">
                  <q-date v-model="filter.al" mask="DD/MM/YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="time_stamp" :props="props">
              {{ formatDate(props.row.time_stamp) }}
            </q-td>
            <q-td key="parametro" :props="props">
              {{ props.row.parametro }}
            </q-td>
            <q-td key="valore" :props="props">
              <span v-if="props.row.valore===null">null</span>
              <span v-else>{{ props.row.valore }}</span>
            </q-td>
            <q-td key="alert" :props="props">
              <tipo-allarme :tipo="props.row.tipo" />
            </q-td>
            <q-td key="actions" :props="props">
              <q-btn
                size="11px"
                no-caps
                :label="props.row.risolto ? 'Risolto' : 'Risolvi'"
                :color="props.row.risolto ? 'green text-white' : 'blue-grey-2 text-black'"
                @click="risolvi(props.row)" />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="showDialogAllarme" square>
      <q-card class="q-pa-sm" style="width: 600px">
        <q-card-section>
          <div class="row">
            <div class="text-h6">{{ titleDialog }}</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </div>
          <div class="text-subtitle2 text-blue-grey-8">
            {{ subTitleDialog }}
            <tipo-allarme :tipo="elemCurr.tipo" />
          </div>
        </q-card-section>

        <q-card-section>
          <q-input
            style="max-width: 100%; margin-bottom: 15px; max-height: 100px"
            type="textarea"
            outlined
            v-model="elemCurr.note"
            dense
            :disable="elemCurr.risolto"
            :readonly="elemCurr.risolto"
            label="Note *" />
        </q-card-section>
        <q-card-section>
          <p v-if="elemCurr.risolto">
            Allarme risolto il <strong>{{formatDate(elemCurr.data_risoluzione)}}</strong>
            da <strong>{{elemCurr.nome}}</strong>
          </p>
        </q-card-section>

        <q-card-actions
          v-if="!elemCurr.risolto"
          class="q-pr-md q-pb-md"
          align="right">
          <q-btn flat label="Annulla" v-close-popup />
          <q-btn
            label="Risolvi"
            color="light-blue-6"
            :disable="!elemCurr.note"
            @click="salva"
            v-close-popup  />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { date } from "quasar";
import TipoAllarme from './TipoAllarme.vue'

export default {
  name: "Allarmi",
  components: { 'tipo-allarme': TipoAllarme },
  data: () => ({
    allarmi: [],
    loading: true,
    filter: {},
    pagination: {
      sortBy: "time_stamp",
      descending: true,
      page: 1,
      rowsPerPage: 100,
      rowsNumber: 100
    },
    columns: [
      {
        name: "time_stamp",
        align: "left",
        label: "DATA - ORA",
        field: "time_stamp",
        sortable: false
      },
      {
        name: "parametro",
        required: true,
        label: "PARAMETRO",
        align: "left",
        field: "parametro",
        sortable: false
      },
      {
        name: "valore",
        required: true,
        label: "VALORE LETTURA",
        align: "right",
        field: "valore",
        sortable: false
      },
      {
        name: "alert",
        required: true,
        label: "TIPO ALLERTA",
        align: "right",
        field: "tipo",
        sortable: false
      },
      {
        name: "actions",
        align: "right",
        label: ""
      },
    ],
    showDialogAllarme: false,
    titleDialog: "",
    subTitleDialog: "",
    dialoglabel: "",
    elemCurr: {}
  }),

  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: {}
    })
  },

  methods: {
    onRequest (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      let filter = { casettaId: +this.$route.params.id }
      if (this.filter.dal)
        filter.dal = this.formatDateForDb(this.filter.dal)
      if (this.filter.al)
        filter.al = this.formatDateForDb(this.filter.al)

      this.loading = true

      this.$http.post("/api/allarmi/casetta", { pagination: props.pagination, filter: filter })
        .then((result) => {
          if (result.data && result.data.length) {
            this.pagination.rowsNumber = +result.data[0].full_count
            this.allarmi = result.data  
            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
          } else {
            this.pagination.rowsNumber = 0
            this.allarmi = []
            this.pagination.page = 0
            this.pagination.rowsPerPage = 0
          }
          this.loading = false
        });
    },

    gotoPage() {
      this.onRequest({
        pagination: this.pagination,
        filter: this.filter
      })
    },

    salva() {
      if (!this.elemCurr.risolto) {
        let toSave = {
          id: this.elemCurr.id,
          note: this.elemCurr.note
        };

        this.$http.put("/api/allarme/resolve", toSave)
          .then(() => {
            this.$q.notify({
              message: "Allarme risolto",
              color: "green",
              timeout: 1000
            });
            this.gotoPage();
          })
          .catch((err) => {
            this.$q.notify({
              message: "Errore durante il salvataggio",
              color: "red",
              timeout: 1000
            });
            console.log("Errore durante il salvataggio", err);
          });
      }
    },

    extractData(val) {
      return date.extractDate(val, "DD/MM/YYYY HH:mm");
    },

    checkDate(val) {
      return (
        date.isValid(date.extractDate(val, "DD/MM/YYYY")) || "Formato non valido."
      );
    },

    formatDateForDb(val) {
      return date.formatDate(date.extractDate(val, "DD/MM/YYYY"), "YYYY-MM-DD");
    },

    formatDate(val) {
      return date.formatDate(val, "DD/MM/YYYY - HH:mm");
    },

    risolvi(row) {
      this.showDialogAllarme = true;
      this.titleDialog = "Manutenzione del " + this.formatDate(row.time_stamp);
      this.subTitleDialog = "Parametro: " + row.parametro;
      this.elemCurr = JSON.parse(JSON.stringify(row))
    }
  }
};
</script>

<style lang="sass">
.sticky-header-table
  /* height or max-height is important */
  height: 61px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: #f2f2f2

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
