<template>
  <div class="q-pt-md">
    <h6 class="q-mt-none q-mb-none">Operatori per Allarmi</h6>
    <q-table
      table-header-class="bg-blue-grey-1 text-grey-8"
      :data="dati"
      :columns="columns"
      :grid="$q.screen.xs"
      :filter="filter"
      :pagination="pagination"
      row-key="id"
      selection="multiple"
      :selected-rows-label="getSelectedString"
      :selected.sync="operatori">
    </q-table>
    <br />
    <q-btn
      size="11px"
      no-caps
      :disabled="!source || !operatori || !operatori.length"
      color="blue-grey-2 text-black"
      label="Salva"
      @click="scriviOperatori()" />
  </div>
</template>

<script>
export default {
  name: 'Operatori',
  props: ['source'],
  data: () => ({
    casetta: undefined,
    dati: [],
    operatori: [],
    filter: '',
    pagination: {
      sortBy: 'nome',
      descending: false,
      page: 1,
      rowsPerPage: 20
    },
    columns: [
      {
        name: 'nome',
        align: 'left',
        label: 'NOME',
        field: 'nome',
        sortable: true
      },
      {
        name: 'email',
        align: 'left',
        label: 'EMAIL',
        field: 'email',
        sortable: true
      },
      {
        name: 'telefono',
        align: 'left',
        label: 'TELEFONO',
        field: 'telefono',
        sortable: true
      }
    ]
  }),

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.$http.get('/api/secure/operatori/getAll', {})
        .then((results) => {
          this.dati = results.data
        })

      this.$http.get('/api/operatoricasetta/' + this.source.id)
        .then((result) => {
          this.operatori = result.data
        })
    },
    async scriviOperatori() {
      return this.$http.post('/api/operatoricasetta', {
        id: this.source.id,
        operatori: this.operatori.map(p => p.id)
      })
    },
    getSelectedString () {
      return this.operatori.length === 0 ? '' : `operatori selezionati: ${this.operatori.length} su ${this.dati.length}`
    }
  }
}
</script>
