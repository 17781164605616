<template>
  <div>
    <div class="row inline q-mt-md q-gutter-md">
      <q-input
        outlined
        dense
        v-model="input"
        :label="getLabel"
        style="max-width: 400px; width: 400px; margin-top: 16px" />
      <q-btn
        color="light-blue-6"
        style="height: 40px"
        label="Inserisci"
        @click="saveData" />
    </div>
    <div class="fit row wrap justify-start q-mt-lg">
      <div class="col-md-12">
        <div style="margin-top: 10px">
          <q-table
            table-header-class="text-grey-7"
            :title="getTableLabel"
            :data="dati"
            :columns="columns"
            :row-key="row => row.id"
            :grid="$q.screen.xs"
            :filter="filter"
            :pagination="pagination">
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="descrizione" :props="props">
                  {{ props.row.descrizione }}
                  <q-popup-edit
                    v-model="props.row.descrizione"
                    @save="(val, init) => updateData(props.row)">
                    <q-input
                      v-model="props.row.descrizione"
                      dense autofocus counter />
                  </q-popup-edit>
                </q-td>
                <q-td class="text-right" key="actions" :props="props">
                  <q-btn
                    flat
                    round
                    color="red-14"
                    icon="delete"
                    @click="deleteData(props.row)" />
                </q-td>
              </q-tr>
            </template>

            <template v-slot:top-right>
              <q-input
                filled
                dense
                debounce="300"
                v-model="filter"
                placeholder="Cerca">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
          </q-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateAnagrafiche',
  props: ['tipo'],
  data: () => ({
    input: null,
    dati: [],
    filter: '',
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: 'descrizione',
        required: true,
        label: 'DESCRIZIONE',
        align: 'left',
        field: row => row.descrizione,
        format: val => `${val}`,
        sortable: false
      },
      {
        name: 'actions',
        align: 'right',
        label: 'AZIONI'
      }
    ]
  }),
  computed: {
    getLabel() {
      let str = ''
      switch (this.tipo) {
        case 'proprietario':
          str = 'Aggiungi un proprietario'
          break
        case 'produttore':
          str = 'Aggiungi un produttore'
          break
        case 'manutentore':
          str = 'Aggiungi un manutentore'
          break
        case 'comune':
          str = 'Aggiungi un comune'
          break
        case 'serie':
          str = 'Aggiungi una serie casette'
          break
      }
      return str
    },

    getTableLabel() {
      let str = ''
      switch (this.tipo) {
        case 'proprietario':
          str = 'Proprietari'
          break
        case 'produttore':
          str = 'Produttore'
          break
        case 'manutentore':
          str = 'Manutentori'
          break
        case 'comune':
          str = 'Comuni'
          break
        case 'serie':
          str = 'Serie Casette'
          break
      }
      return str
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      /* 
          Tipi:
          - proprietario
          - produttore
          - manutentore
          - comune
          - serie
          */
      this.$http
        .get('/api/secure/anagrafiche/get-by-type', {
          params: { tipo: this.tipo }
        })
        .then((results) => {
          this.dati = results.data
        })
        .catch((err) => {
          console.log('Error on getData Anagrafiche', err)
        })
    },
    saveData() {
      let toSave = {
        descrizione: this.input,
        tipo: this.tipo
      }
      this.$http
        .post('/api/secure/anagrafica/save', toSave, {})
        .then(() => {
          this.$q.notify({
            message: 'Salvataggio riuscito',
            color: 'green',
            timeout: 1000
          })
          this.input = null
          this.getData()
        })
        .catch((err) => {
          console.log('Error on saveData Anagrafiche', err)
          this.$q.notify({
            message: 'Errore durante il salvataggio',
            color: 'red',
            timeout: 1000
          })
        })
    },
    /* logData(row, val) {
      console.log(row.id, val)
    }, */
    updateData(row) {
      let toUpdate = {
        id: row.id,
        descrizione: row.descrizione,
        tipo: this.tipo
      }
      this.$http
        .put('/api/secure/anagrafica/update', toUpdate, {})
        .then(() => {
          this.$q.notify({
            message: 'Salvataggio riuscito',
            color: 'green',
            timeout: 1000
          })
          this.getData()
        })
        .catch((err) => {
          console.log('Error on updateData Anagrafiche', err)
          this.$q.notify({
            message: 'Errore durante il salvataggio',
            color: 'red',
            timeout: 1000
          })
        })
    },
    deleteData(row) {
      this.$http
        .delete('/api/secure/anagrafica/delete', {
          params: { id: row.id }
        })
        .then(() => {
          this.$q.notify({
            message: 'Salvataggio riuscito',
            color: 'green',
            timeout: 1000
          })
          this.getData()
        })
        .catch((err) => {
          console.log('Error on deleteData Anagrafiche', err)
          this.$q.notify({
            message: 'Errore durante il salvataggio',
            color: 'red',
            timeout: 1000
          })
        })
    }
  }
}
</script>

<style></style>
