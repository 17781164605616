<template>
  <div class="q-mx-lg q-mb-xl">
    <div class="row q-mt-xl q-mb-xl">
      <div class="col-12 col-md-12">
        <h5 class="q-ma-none text-bold">Parametri qualità dell'acqua</h5>
      </div>
    </div>
    <q-table
      table-header-class="bg-blue-grey-1 text-grey-8"
      :data="dati"
      :columns="columns"
      row-key="name"
      :grid="$q.screen.xs"
      :pagination.sync="pagination"
      hide-bottom>
      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <q-btn
            no-caps
            size="11px"
            color="blue-grey-2 text-black"
            label="Dettagli"
            @click="openDetails(props.row)">
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <q-dialog v-model="showDialog" persistent square>
      <q-card class="q-pa-sm" style="width: 350px">
        <q-card-section>
          <div class="row">
            <div class="text-h6">{{regole.parametro}}</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup @click="reset" />
          </div>
        </q-card-section>

        <q-card-section>
          <div>
            <q-input
              outlined
              v-model.number="regole.min"
              label="Min"
              dense
              clearable
              type="number" />
          </div>
          <br />
          <div>
            <q-input
              outlined
              v-model.number="regole.max"
              label="Max"
              dense
              clearable
              type="number" />
          </div>
          <br />
          <div>
            <q-input
              outlined
              v-model.number="regole.variazione"
              label="Periodo Soglia"
              dense
              clearable
              type="number" />
          </div>
          <br />
          <div>
            <q-input
              outlined
              v-model.number="regole.frequenza_alert"
              label="Frequenza Alert"
              dense
              clearable
              type="number" />
          </div>
          <br />
          <div>
            <q-select
              outlined
              v-model="regole.mail"
              :options="allarmiOptions"
              label="Tipo Allarme minimo da notificare via mail"
              emit-value
              map-options />
          </div>
          <br />
          <div>
            <q-select
              outlined
              v-model="regole.sms"
              :options="allarmiOptions"
              label="Tipo Allarme minimo da notificare via SMS"
              emit-value
              map-options />
          </div>
        </q-card-section>

        <q-card-actions class="q-pr-md q-pb-md" align="right">
          <q-btn
            style="text-decoration: underline"
            no-caps
            flat
            color="black"
            label="Annulla"
            v-close-popup
            @click="reset" />
          <q-btn
            label="Salva"
            color="light-blue-6"
            @click="salvaRegole()"
            v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { date } from 'quasar'

export default {
  name: 'Uploader',
  data: () => ({
    dati: [],
    allarmiOptions: [
      { label: 'Tutti', value: 0 },
      { label: 'Allarme', value: 1 },
      { label: 'Emergenza', value: 2 },
      { label: 'Critico', value: 3 },
      { label: 'Valore mancante', value: 4 },
      { label: 'Nessuno', value: 6 }
    ],
    regole: {
      frequenza_alert: null,
      min: null,
      max: null,
      variazione: null,
      mail: 0,
      sms: 0
    },
    showDialog: false,
    pagination: {
      sortBy: 'parametro',
      descending: false,
      page: 1,
      rowsPerPage: 0
    },
    columns: [
      {
        name: 'parametro',
        required: true,
        label: 'PARAMETRO',
        align: 'left',
        field: 'parametro',
        sortable: false
      },
      {
        name: 'unita',
        required: true,
        label: 'UNITÀ DI MISURA',
        align: 'left',
        field: 'um',
        sortable: false
      },
      {
        name: 'min',
        align: 'left',
        label: 'MIN',
        field: 'min',
        sortable: false
      },
      {
        name: 'max',
        align: 'left',
        label: 'MAX',
        field: 'max',
        sortable: false
      },
      {
        name: 'variazione',
        align: 'left',
        label: 'PERIODO SOGLIA',
        field: 'variazione',
        sortable: false
      },
      {
        name: 'frequenza_alert',
        align: 'left',
        label: 'FREQUENZA ALERT',
        field: 'frequenza_alert',
        sortable: false
      },
      {
        name: 'actions',
        align: 'right',
        label: 'AZIONI'
      }
    ]
  }),
  mounted() {
    this.getData()
  },
  methods: {
    reset() {
      this.regole.min = null
      this.regole.max = null
      this.regole.variazione = null
      this.regole.frequenza_alert = null
      this.regole.mail = 0
      this.regole.sms = 0
      this.showDialog = false
    },
    openDetails(item) {
      this.showDialog = true
      this.regole = Object.assign({}, item)
    },
    getData() {
      this.$http.get('/api/secure/regole/all', { })
        .then((result) => {
          this.dati = result.data
        })
    },
    salvaRegole() {
      this.$http
        .put('/api/secure/regola/update', this.regole)
        .then(() => {
          this.$q.notify({
            message: 'Aggiornamento regola riuscito',
            color: 'green',
            timeout: 2000
          })
          this.getData()
        })
        .catch((err) => {
          this.$q.notify({
            message: 'Errore aggiornamento regola',
            color: 'red',
            timeout: 2000
          })
          console.log('Errore aggiornamento regola', err)
        })
    }
  }
}
</script>

<style>
.q-table--no-wrap th,
.q-table--no-wrap td .text-wrap {
  white-space: normal;
}
@media screen and (max-width: 1750px) {
  .q-table--no-wrap th,
  .q-table--no-wrap td {
    white-space: normal !important;
  }
}
</style>
