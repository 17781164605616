import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Casette from '../views/Casette.vue'
import Casetta from '../views/Casetta.vue'
import DownloadReport from '../views/DownloadReport.vue'
import RisparmioReport from '../views/RisparmioReport.vue'
import QualitaReport from '../views/QualitaReport.vue'
import Regole from '../views/Regole.vue'
import Anagrafiche from '../views/Anagrafiche.vue'
import Ruoli from '../views/Ruoli.vue'
// import Test from '../views/Test.vue'
// import FilippettiView from '../views/FilippettiView.vue'
import OperatoriAllerte from '../views/OperatoriAllerte.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    alias: '/dashboard'
  },
  {
    path: '*',
    redirect: '/dashboard'
  },
  {
    path: '/casette',
    name: 'Casette',
    component: Casette
  },
  {
    path: '/casette/:id',
    name: 'Casetta',
    component: Casetta
  },
  {
    path: '/download',
    name: 'DownloadReport',
    component: DownloadReport
  },
  {
    path: '/risparmio',
    name: 'RisparmioReport',
    component: RisparmioReport
  },
  {
    path: '/qualita',
    name: 'QualitaReport',
    component: QualitaReport
  },
  {
    path: '/regole',
    name: 'Regole',
    component: Regole
  },
  {
    path: '/anagrafiche',
    name: 'Anagrafiche',
    component: Anagrafiche
  },
  {
    path: '/operatoriAllerte',
    name: 'OperatoriAllerte',
    component: OperatoriAllerte
  },
  {
    path: '/ruoli',
    name: 'Ruoli',
    component: Ruoli
  }
  /* {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/iot-config',
    name: 'FilippettiView',
    component: FilippettiView
  } */
]

const router = new VueRouter({ routes })

export default router
