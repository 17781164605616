<template>
  <div class="q-mx-lg">
    <div class="row q-mt-xl q-mb-xl">
      <div class="col-12 col-md-8">
        <h5 class="q-ma-none text-bold">Case dell'Acqua</h5>
      </div>
      <div class="col-12 col-md-4 text-right">
        <q-btn stretch color="light-blue-6" @click="addCasetta()" icon="add">
          Aggiungi Casa dell'Acqua
        </q-btn>
      </div>
    </div>
    <div>
      <q-input
        class="col col-md-8"
        style="max-width: 50%; margin-right: 10px"
        outlined
        color="black"
        dense
        v-model="filter"
        debounce="300"
        placeholder="Cerca casa dell'acqua">
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
    </div>
    <div class="q-mt-lg">
      <q-table
        table-header-class="bg-blue-grey-1 text-grey-8"
        class="casette-table"
        :data="dati"
        :columns="columns"
        :grid="$q.screen.xs"
        :filter="filter"
        :pagination="pagination">
        <template v-slot:body-cell-stato="props">
          <q-td :props="props">
            <div v-if="props.row.stato === 'attiva'">
              <q-icon
                name="circle"
                class="text-green"
                style="font-size: 16px; line-height: 0.8" />
              Attiva
            </div>

            <div v-if="props.row.stato === 'da attivare'">
              <q-icon
                name="circle"
                class="text-grey"
                style="font-size: 16px; line-height: 0.8" />
              Da attivare
            </div>

            <div v-if="props.row.stato === 'in manutenzione'">
              <q-icon
                name="circle"
                class="text-orange"
                style="font-size: 16px; line-height: 0.8" />
              In manutenzione
            </div>

            <div v-if="props.row.stato === 'chiusa'">
              <q-icon
                name="circle"
                class="text-red"
                style="font-size: 16px; line-height: 0.8" />
              Chiusa
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-alert="props">
          <q-td :props="props">
            <q-avatar
              size="sm"
              text-color="white"
              :color="props.row.allarme_attivo > 2 ? 'red' : 'orange'"
              v-if="props.row.allarme_attivo !== null">
              <q-icon name="warning" />
            </q-avatar>
          </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              size="11px"
              no-caps
              color="blue-grey-2 text-black"
              label="Dettagli"
              @click="openDetails(props.row.id)" />
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Casette',
  data: () => ({
    dati: [],
    filter: '',
    pagination: {
      sortBy: 'comune',
      descending: false,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: 'indirizzo',
        align: 'left',
        label: 'INDIRIZZO',
        field: 'indirizzo',
        sortable: true
      },
      {
        name: 'indicazioni_aggiuntive',
        align: 'left',
        label: 'INDICAZIONI AGGIUNTIVE',
        field: 'indicazioni_aggiuntive',
        sortable: true
      },
      {
        name: 'comune',
        align: 'left',
        label: 'COMUNE',
        field: 'comune',
        sortable: true
      },
      {
        name: 'endpoint',
        align: 'left',
        label: 'END POINT',
        field: 'endpoint',
        sortable: true
      },
      {
        name: 'stato',
        align: 'left',
        label: 'STATO',
        field: 'stato',
        sortable: true
      },
      {
        name: 'alert',
        align: 'left',
        label: 'ALERT',
        field: 'alert',
        sortable: true
      },
      {
        name: 'actions',
        align: 'right',
        label: 'AZIONI'
      }
    ]
  }),
  computed: {
    getUserByDb() {
      return this.$store.getters.getUserByDb
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    addCasetta() {
      this.$router.push({
        name: 'Casetta',
        params: { id: 'new' }
      })
    },

    openDetails(id) {
      this.$router.push({
        name: 'Casetta',
        params: { id: id }
      })
    },

    getData() {
      const checkAllarmi = () => {
        if (this.dati && this.dati.length) {
          this.dati.forEach((c) => {
            this.$http.get('/api/letture/casetta/has_allarmi/' + c.id)
              .then((result) => {
                this.$set(c, 'has_allarmi', result.data.has_allarmi)
                this.$set(c, 'allarme_attivo', result.data.allarme_attivo)
              })
          })
        }
      }

      if (this.$_.includes(this.getUserByDb.ruoli, 'operatore') &&
          !(this.$_.includes(this.getUserByDb.ruoli, 'admin') ||
            this.$_.includes(this.getUserByDb.ruoli, 'casette'))) {
        this.$http.get('/api/secure/casette/manutentore', {
          params: {
            manutentore: this.getUserByDb.manutentore,
          },
        }).then((results) => {
          this.dati = results.data
          checkAllarmi()
        })
      } else {
        this.$http.get('/api/secure/casette/all').then((results) => {
          this.dati = results.data

          this.$http.get('/api/v1/edge-casette').then((result) => {
            this.dati.forEach((casetta) => {
              const edge = result.data.find(cas => cas.id === 'casetta_' + casetta.id)
              if (edge) this.$set(casetta, 'endpoint', edge.endpoint)
            })
          })

          checkAllarmi()
        })
      }
    }
  }
}
</script>
<style>
.casette-table .q-table tr td:first-child {
  width: 25%;
}
.casette-table.q-table--no-wrap td,
.casette-table.q-table--no-wrap th {
  white-space: normal !important;
}
</style>
