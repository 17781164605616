<template>
  <div class="q-mx-lg">
    <div class="row q-mt-xl q-mb-xl">
      <div class="col-12 col-md-12">
        <h5 class="q-ma-none text-bold">Risparmio Energetico</h5>
      </div>
    </div>
    <div class="row inline q-gutter-md q-pb-xl">
      <q-select
        outlined
        dense
        style="max-width: 400px; width: 400px;"
        class="c-casetta"
        v-model="casetta"
        @input="selCasetta"
        :options="casetta_options"
        label="Seleziona Casa dell'Acqua"
        map-options />

      <q-select
        outlined
        dense
        v-model="anno"
        v-if="consumi && consumi.length"
        :options="consumi"
        :option-value="opt => Object(opt) === opt && 'anno' in opt ? opt.anno : null"
        :option-label="opt => Object(opt) === opt && 'anno' in opt ? opt.anno : '- Null -'"
        map-options
        style="min-width: 150px; max-width: 300px" />

      <q-btn
        :disabled="!casetta || !anno"
        v-if="consumi && consumi.length"
        color="light-blue-6"
        label="Visualizza"
        @click="visualizzaReport"
        style="height: 40px">
      </q-btn>
    </div>

    <q-separator v-if="body" />
    <div class="row" v-if="body">
      <q-separator />
      <div class="col-lg-6 col-md-6">
        <h6>Acqua Erogata</h6>
      </div>
      <div class="col-lg-4 col-md-4 q-mt-lg q-gutter-md text-right">
        <q-btn color="green" label="Scarica xls" @click="exportXls"></q-btn>
        <q-btn color="red" label="Stampa PDF" @click="exportPdf"></q-btn>
      </div>
      <div v-html="body"></div>
    </div>
    <div v-if="!body && flagVisualizza">
      <q-banner inline-actions rounded class="bg-amber-1 text-grey-8">
        Non ci sono abbastanza dati per generare un report. Cambia le date e riprova.
      </q-banner>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { date } from 'quasar'
import fileSaver from 'file-saver'

export default {
  name: 'RisparmioReport',
  data: () => ({
    casetta: null,
    casetta_options: [],
    consumi: [],
    anno: null,
    body: null,
    flagVisualizza: false
  }),

  mounted() {
    this.getCasette()
  },

  methods: {
    getCasette() {
      this.$http.get('/api/secure/casette/all', { })
        .then((results) => {
          let data = results.data
          this.$_.forEach(data, (el) => {
            let obj = {}
            obj.value = el.id
            obj.label = el.indirizzo +
              (el.indicazioni_aggiuntive
                ? ' - ' + el.indicazioni_aggiuntive
                : '')

            this.casetta_options.push(obj)
          })
        })
    },

    selCasetta() {
      this.$http.get('/api/consumiannuali/' + this.casetta.value)
        .then((result) => {
          this.consumi = result.data
          this.consumi.forEach((c) => {
            c.casetta = this.casetta.label
          })
          if (this.consumi.length) this.anno = this.consumi[0]
        })
    },

    visualizzaReport() {
      this.anno.tipo_export = null
      this.$http.post('/api/v1/risparmio', this.anno)
        .then((result) => {
          this.body = result.data
          this.flagVisualizza = true
        })
    },

    exportXls() {
      this.anno.tipo_export = 'xls'
      this.$http.post('/api/v1/risparmio', this.anno)
        .then((result) => {
          var byteCharacters = atob(result.data)
          var byteNumbers = new Array(byteCharacters.length)

          for (var i = 0; i < byteCharacters.length; i++)
            byteNumbers[i] = byteCharacters.charCodeAt(i)

          var byteArray = new Uint8Array(byteNumbers)
          var blob = new Blob([byteArray], {
            type: 'application/vnd.ms-excel'
          })

          fileSaver.saveAs(blob, 'Risparmio.xls')
        })
    },

    exportPdf() {
      this.anno.tipo_export = 'pdf'
      this.$http.post('/api/v1/risparmio', this.anno, { responseType: 'blob' })
        .then((result) => {
          var blob = new Blob([result.data], { type: 'application/pdf' })

          fileSaver.saveAs(blob, 'Risparmio.pdf')
        })
    }
  }
}
</script>

<style>
.q-table--no-wrap th,
.q-table--no-wrap td .text-wrap {
  white-space: normal;
}
@media screen and (max-width: 1750px) {
  .q-table--no-wrap th,
  .q-table--no-wrap td {
    white-space: normal !important;
  }
}
</style>
