<template>
  <div>
    <div class="q-mt-md">
      <q-table
        table-header-class="text-grey-7"
        class="sticky-header-table"
        style="height: 65vh"
        virtual-scroll
        dense
        title="Letture"
        :data="letture"
        :columns="columns"
        row-key="time_stamp"
        :grid="$q.screen.xs"
        :loading="loading"
        :filter="filter"
        :pagination.sync="pagination"
        :rows-per-page-options="[]"
        @request="onRequest"
        binary-state-sort>
        <template v-slot:top-right>
          <q-input
            outlined
            v-model="filter.dal"
            mask="##/##/####"
            :rules="[checkDate]"
            label="Dal"
            dense
            class="q-ml-md q-mt-md"
            style="width: 150px">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy1i"
                  transition-show="scale"
                  transition-hide="scale">
                  <q-date v-model="filter.dal" mask="DD/MM/YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-input
            outlined
            v-model="filter.al"
            mask="##/##/####"
            :rules="[checkDate]"
            label="Al"
            dense
            class="q-ml-md q-mt-md"
            style="width: 150px">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy2i"
                  transition-show="scale"
                  transition-hide="scale">
                  <q-date v-model="filter.al" mask="DD/MM/YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="time_stamp" :props="props">
              {{ formatDate(props.row.time_stamp) }}
            </q-td>
            <q-td key="colore" :props="props">
              <span class="text-wrap">{{ props.row.colore }}</span>
            </q-td>
            <q-td key="torbidita" :props="props">
              <span class="text-wrap">{{ props.row.torbidita }}</span>
            </q-td>
            <q-td key="ph" :props="props">
              <span class="text-wrap">{{ props.row.ph }}</span>
            </q-td>
            <q-td key="cloro" :props="props">
              <span class="text-wrap">{{ props.row.cloro }}</span>
            </q-td>
            <q-td key="temperatura" :props="props">
              <span class="text-wrap">{{ props.row.temperatura }}</span>
            </q-td>
            <q-td key="conducibilità" :props="props">
              <span class="text-wrap">{{ props.row.conducibilita }}</span>
            </q-td>
            <q-td key="alert" :props="props">
              <q-avatar size="26px" text-color="white" color="orange" v-if="props.row.has_allarmi">
                <q-icon name="warning" />
              </q-avatar>
            </q-td>
            <q-td key="azioni" :props="props">
              <q-btn
                size="11px"
                color="blue-grey-2 text-black"
                no-caps
                label="Dettagli"
                @click="openDetails(props.row)" />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="showDialog" square>
      <q-card class="q-pa-sm" style="width: 600px">
        <q-card-section>
          <div class="row">
            <div class="text-h6">
              Qualità dell'acqua del {{formatDate(elemCurr.time_stamp)}}
            </div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </div>
        </q-card-section>
        <q-card-section>
          <div class="q-py-sm">
            <q-list bordered separator>
              <q-item v-for="par in parametri" :key="par.id">
                <q-item-section>
                  <q-item-label><b>{{par.parametro}}</b></q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-item-label>
                    <tipo-allarme :tipo="getAllarme(par.id)" v-if="hasAllarme(par.id)" />
                    <span v-if="elemCurr[par.id]===null">null</span>
                    <span v-else>{{ elemCurr[par.id] }}</span>
                    {{par.um}}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { date } from 'quasar'
import TipoAllarme from './TipoAllarme.vue'

export default {
  name: 'Analisi',
  components: { 'tipo-allarme': TipoAllarme },
  data: () => ({
    letture: [],
    parametri: [],
    loading: true,
    filter: {},
    pagination: {
      sortBy: 'time_stamp',
      descending: true,
      page: 1,
      rowsPerPage: 100,
      rowsNumber: 100
    },
    columns: [
      {
        name: 'time_stamp',
        align: 'left',
        label: 'DATA - ORA',
        field: 'time_stamp',
        format: (val) => date.formatDate(val, 'DD/MM/YYYY HH:mm'),
        sortable: false
      },
      {
        name: 'colore',
        required: true,
        label: 'Colore (Hazen)',
        align: 'right',
        field: (row) => row.colore,
        sortable: false
      },
      {
        name: 'torbidita',
        required: true,
        label: 'Torbidità (FTU/NTU)',
        align: 'right',
        field: (row) => row.torbidita,
        sortable: false
      },
      {
        name: 'ph',
        required: true,
        label: 'PH (ph)',
        align: 'right',
        field: (row) => row.ph,
        sortable: false
      },
      {
        name: 'cloro',
        required: true,
        label: 'CLORO LIBERO (mg/l)',
        align: 'right',
        field: (row) => row.cloro,
        sortable: false
      },
      {
        name: 'temperatura',
        required: true,
        label: 'TEMPERATURA (°C)',
        align: 'right',
        field: (row) => row.temperatura,
        sortable: false
      },
      {
        name: 'conducibilità',
        required: true,
        label: 'CONDUCIBILITÀ (mS/cm)',
        align: 'right',
        field: (row) => row.conducibilita,
        sortable: false
      },
      {
        name: 'alert',
        required: true,
        label: 'ALERT',
        align: 'center',
        sortable: false
      },
      {
        name: 'azioni',
        align: 'right',
        label: '',
        sortable: false
      },
    ],
    showDialog: false,
    elemCurr: {}
  }),

  mounted() {
    // get initial data from server (1st page)
    this.onRequest({
      pagination: this.pagination,
      filter: {}
    })
  },

  methods: {
    onRequest (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      let filter = { casettaId: +this.$route.params.id }
      if (this.filter.dal)
        filter.dal = this.formatDateForDb(this.filter.dal)
      if (this.filter.al)
        filter.al = this.formatDateForDb(this.filter.al)

      this.loading = true

      this.$http.post('/api/letture/casetta', { pagination: props.pagination, filter: filter })
        .then((result) => {
          if (result.data && result.data.length) {
            this.pagination.rowsNumber = +result.data[0].full_count
            this.letture = result.data  
            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
          } else {
            this.pagination.rowsNumber = 0
            this.letture = []
            this.pagination.page = 0
            this.pagination.rowsPerPage = 0
          }
          this.loading = false
        })

      if (!this.parametri || !this.parametri.length)
        this.$http.get('/api/secure/regole/all', { })
          .then((result) => { this.parametri = result.data })
    },

    hasAllarme(par) {
      if (!this.elemCurr || !this.elemCurr.allarmi) return false
      return this.elemCurr.allarmi.find((a) => a.parametro === par)
    },

    getAllarme(par) {
      if (!this.elemCurr || !this.elemCurr.allarmi) return false
      return this.elemCurr.allarmi.find((a) => a.parametro === par).tipo
    },

    checkDate(val) {
      return (
        date.isValid(date.extractDate(val, 'DD/MM/YYYY')) || 'Formato non valido.'
      )
    },

    formatDateForDb(val) {
      return date.formatDate(date.extractDate(val, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    },

    formatDate(val) {
      return date.formatDate(val, 'DD/MM/YYYY - HH:mm')
    },

    openDetails(row) {
      if (Object.keys(row).includes('allarmi')) {
        this.showDialog = true
        this.elemCurr = JSON.parse(JSON.stringify(row))
      } else {
        this.$http.post('/api/letture/allarmi', { casetta_id: row.casetta_id, time_stamp: row.time_stamp })
          .then((result) => {
            if (result.data && result.data.length) row.allarmi = result.data  
            else row.allarmi = []

            this.showDialog = true
            this.elemCurr = JSON.parse(JSON.stringify(row))
          })
      }
    }
  }
}
</script>

<style lang="sass">
.sticky-header-table
  /* height or max-height is important */
  height: 61px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: #f2f2f2
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
