<template>
  <q-card>
    <q-card-section class="text-center">
      <span class="text-h4">{{ getTotale }} litri</span><br />
      di acqua erogata nell'anno {{ getYear }}
    </q-card-section>
    <q-card-section>
      <apexchart
        ref="chart"
        type="area"
        height="200"
        :options="chartOptions"
        :series="series">
      </apexchart>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'KpiAcquaErogata',
  components: {},
  data: () => ({
    totale: 0,
    series: [
      {
        name: 'Acqua Erogata',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ],
    chartOptions: {
      chart: {
        id: 'chartid',
        zoom: { enabled: false },
        toolbar: { show: false }
      },
      dataLabels: { enabled: false },
      stroke: { curve: 'smooth' },
      xaxis: {
        categories: [
          'Gen',
          'Feb',
          'Mar',
          'Apr',
          'Mag',
          'Giu',
          'Lug',
          'Ago',
          'Set',
          'Ott',
          'Nov',
          'Dic',
        ]
      }
    }
  }),

  mounted() {
    this.getKpiAcquaErogata()
  },
  computed: {
    getYear() {
      return new Date().getFullYear()
    },
    getTotale() {
      return this.totale
    }
  },
  methods: {
    getKpiAcquaErogata() {
      this.$http.get('/api/consumi/anno', { })
        .then((result) => {
          if (result.data.length) {
            result.data.forEach((el) => {
              this.series[0].data[el.mese - 1] = +el.acqua
              this.totale += +el.acqua
            })

            this.totale = parseInt(this.totale)
            this.updateSeriesLine()
          }
        })
    },

    updateSeriesLine() {
      this.$refs.chart.updateSeries(
        [{ data: this.series[0].data }],
        false,
        true
      )
    }
  }
}
</script>

<style></style>
